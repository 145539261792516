import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Container,
    Typography,
    Icon,
    createMuiTheme,
    ThemeProvider,
    TextField,
    LinearProgress,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    FormGroup,
    MenuItem,
    FormControl,
    InputLabel,
    Input,
    FormHelperText, Select, IconButton
} from "@material-ui/core";

import defaultLightPalette from "../../services/defaultLightPalette";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";

import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";

import {DataGrid} from "@material-ui/data-grid";
import {fetchOrganizations, selectAllOrganizations, selectOrganizationById, updateOrganization} from "../../store/reducers/organizationsSlice";
import Link from "@material-ui/core/Link";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import ViewComfy from "@material-ui/icons/ViewComfy";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import Grid from "@material-ui/core/Grid";
import tdb from "../../services/TranslateDB";

export default function Organizations() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.organizations.status);
    const organizations = useSelector(state => selectAllOrganizations(state));
    const [filter, setFilter] = useState();
    const [editingOrganization, setEditingOrganization] = useState(null);

    const [displayMode, setDisplayMode] = useState('table');
    const handleDisplayMode = (mode) => setDisplayMode(mode);

    // alert(status);
    useEffect(()=>{
        if (status === 'idle') {
            console.log("FETCHING USERS");
            dispatch(fetchOrganizations());
        }
    }, [status, dispatch]);

    useEffect(()=>{
        console.log("USERS", organizations);
    }, [organizations]);

    if (status !== "succeeded") {
        return <Container disableGutters={true}>
            <ThemeProvider theme={lightTheme}>
                <Card>
                    <CardHeader title={t('Organizations')}/>
                    <CardContent>
                        {
                            status === "failed"
                                ?   <Typography>Error loading organizations</Typography>
                                :   <LinearProgress color="secondary" />
                        }
                    </CardContent>
                </Card>
            </ThemeProvider>
        </Container>
    }

    const getViewCell = (params) => {
        return <Button variant="contained" color="default" size="small">{t('Search')}</Button>
    };

    const getUrlCell = (params) => {
        return <Button variant="text" color="default" size="small" href={params.value} target={"_blank"} component={Link}>{t('Open')}</Button>
    };

    const handleEdit = (id) => setEditingOrganization(id)
    const handleUnEdit = () => setEditingOrganization(null)

    const handleDelete = (id) => {

    }

    const _renderName = (params) => {
        return params.row.firstName+' '+params.row.lastName
    }

    const _renderCounter = (params) => {
        if (params.field in params.row) {
            return params.row[params.field];
        }
        else if (params.field+'_count' in params.row) {
            const value = params.row[params.field+'_count'];
            return Array.isArray(value) ? value[0] : value;
        }
        return null;
    }

    const _hasKC = (params) => {
        if (params.row.id_kc && String(params.row.id_kc).length) {
            return params.row.id_kc;
        }
        return null;
    }

    const _actions = (params) => {
        return <>
            <IconButton>
                <EditIcon onClick={() => handleEdit(params.row.id)} />
            </IconButton>
            {/*<DeleteIcon onClick={() => handleDelete(params.row.id)} />*/}
        </>
    }

    const columns = [
        {field:"name",          headerName:"Name",      flex:2, renderCell: _renderName},
        {field:"type",          headerName:"Type",      flex:1},
        {field:"collections",   headerName:"Col",       width:80, resizable: false, renderCell: _renderCounter},
        {field:"images",        headerName:"Img",       width:80, resizable: false, renderCell: _renderCounter},
        {field:"objects",       headerName:"Obj",       width:80, resizable: false, renderCell: _renderCounter},
    ];


    const handleSetFilter = (event) => setFilter(event.target.value);
    const filtered = filter
        ? organizations.filter(row=>(
            row.name.toLowerCase().includes(filter.toLowerCase())
            || row.email.toLowerCase().includes(filter.toLowerCase())
        ))
        : organizations
    ;

    const rows = filtered.map(row=>({
        ...row
    }));

    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme}>
            <Card>
                <CardHeader
                    title={t('Organizations')}
                    action={
                        <Grid>
                            <TextField onChange={handleSetFilter} value={filter} variant={"standard"} label={t("Search")} className={classes.searchField} />
                            <ToggleButtonGroup size="medium" exclusive value={displayMode} onChange={handleDisplayMode}>
                                <ToggleButton value="table" className={classes.toggleButtons}>
                                    <ViewCompactIcon fontSize="default"/>
                                </ToggleButton>
                                <ToggleButton value="grid" className={classes.toggleButtons}>
                                    <ViewComfy fontSize="default"/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    }
                />
                <CardContent>
                    <div style={{height:window.innerHeight - 400}}>
                        <DataGrid columns={columns} rows={rows} pageSize={20} />
                    </div>
                </CardContent>
                <CardActions>
                    <Button variant={"contained"} color={"secondary"}>{t("CreateOrganization")}</Button>
                </CardActions>
            </Card>
            {
                <EditOrganization userId={editingOrganization} onClose={handleUnEdit} />
            }
        </ThemeProvider>
    </Container>
};

function EditOrganization({organizationId, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const organization = useSelector(state => selectOrganizationById(state, organizationId));

    const [type, setType] = useState();
    const [name, setName] = useState();

    useEffect(() => {
        if (organization) {
            console.log(organization)
            setType(organization.type);
            setName(organization.name);
        }
    }, [organization])

    const handleName  = (event) => setName(event.target.value);
    const handleType  = (event) => setType(event.target.value);

    const handleSave = () => {
        dispatch(updateOrganization({
            organizationId: organizationId,
            body : {
                name: name,
            }
        }))
    }

    const types = ['standard'];

    return <Dialog open={!!organizationId} title={t("EditOrganization")} maxWidth={"md"} fullWidth onClose={onClose}>
        <DialogTitle>
            Edit Organization
        </DialogTitle>
        <DialogContent>
            Changer les valeurs pour <strong>{organization && organization.name}</strong>
        </DialogContent>
        <DialogContent>
            {
                organization && <FormGroup>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel className={classes.label} shrink={true}>Type</InputLabel>
                        <Select
                            onChange={handleType}
                            value={type}
                        >
                            {types.map((option, index)=>{
                                return <MenuItem key={option} value={option}>{option}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>Le type d'utilisateur</FormHelperText>
                    </FormControl>
                    <TextField variant={"outlined"}
                               className={classes.formControl}
                               value={name}
                               onChange={handleName}
                               label={"Title"}
                               helperText={"Titre de l'interface"}
                    />
                </FormGroup>
            }
        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} color={"secondary"} onClick={handleSave}>{t('Save')}</Button>
        </DialogActions>
    </Dialog>
}

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        '& label': {
            backgroundColor: 'white !important',
            color: '#666 !important'
        }
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    searchField: {
        marginRight: theme.spacing(2)
    },
    toggleButtons: {
        height: 50,
    },

}));