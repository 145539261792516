import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/demo';

const fieldTypesAdapter = createEntityAdapter();
const initialState = fieldTypesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all fieldTypes
export const fetchFieldTypes = createAsyncThunk('fieldTypes/fetchFieldTypes', async () => {
    const response = await client.get('/tree/fieldType/all');
    return response.fieldTypes;
});

// THE REDUCERS
const fieldTypesSlice = createSlice({
    name: 'fieldTypes',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchFieldTypes.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchFieldTypes.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched fieldTypes to the array
            fieldTypesAdapter.upsertMany(state, action.payload);
        },
        [fetchFieldTypes.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
    },
});

// export const {  } = fieldTypesSlice.actions;

export default fieldTypesSlice.reducer

export const {
    selectAll: selectAllFieldTypes,
    selectById: selectFieldTypeById,
    selectIds: selectFieldTypeIds,
} = fieldTypesAdapter.getSelectors((state) => state.fieldTypes);

export const selectFieldTypesByInvestigation = createSelector(
    [selectAllFieldTypes, (state, investigationId) => investigationId],
    (fieldTypes, investigationId) => fieldTypes.filter((fieldType) => (
        fieldType.investigations.indexOf(investigationId) >= -1
    ))
);
