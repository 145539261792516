import React, {useEffect, useState} from "react";

import { useSelector, useDispatch } from "react-redux";

import {makeStyles, createMuiTheme, ThemeProvider, withStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";

import { NavLink, Link, useHistory} from "react-router-dom";

import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import SpeedDial from "@material-ui/lab/SpeedDial";

import CollectionsIcon from "@material-ui/icons/Collections";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Surveys from "../Survey/Surveys";
import {useTranslation} from "react-i18next";
import {fetchInvestigations, selectAllInvestigations} from "../../store/reducers/investigationsSlice";
import {fetchCriminalCases, selectAllCriminalCases} from "../../store/reducers/criminalCasesSlice";
import {fetchTasks, selectAllTasks} from "../../store/reducers/tasksSlice";

import ExampleGraph from "./ExampleGraph";
import {getCurrentUser, isGranted} from "../../store/reducers/currentUserSlice";

const Home = () => {
    const classes = useStyles();
    const currentUser = useSelector(getCurrentUser);

    // TODO: replace with nice grant handlers
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);

    const {t} = useTranslation();

    return <Container disableGutters={true}>
        <Typography variant="h1">
            {t("Dashboard")}
        </Typography>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Grid spacing={3} container>

                {lea &&
                    <>
                        <BlockInvestigations h={100} sm={6} xs={12} md={4}/>
                        <BlockCriminalCases  h={100} sm={6} xs={12} md={4}/>
                        <BlockTask           h={100} sm={12} xs={12} md={4}/>
                    </>
                }

                {lea&&<Block xs={12} sm={6} md={6} title={t("Activity")}
                        content={<ul className={classes.ul}>
                            <li>{t('DashboardLastConnection')} : hier</li>
                            <li>{t('DashboardConnected')} : 1</li>
                            <li>{t('DashboardMyPreferences')}</li>
                        </ul>}
                        actions={<Button variant="contained" color="default">{t('See')}</Button>}
                />}
                {lea&&<Block xs={12} sm={6} md={6} title={t("Validations")}
                        content={<ul className={classes.ul}>
                            <li>2 {t('CriminalCases')}</li>
                            <li>0 {t('Investigations')}</li>
                            <li>0 {t('Collections')}</li>
                        </ul>}
                        actions={<Button variant="contained" color="default">{t('See')}</Button>}
                />}
                <Grid item xs={12}>
                    <Surveys title={t("DashboardSurveillanceNotification")} filter />
                </Grid>
                <Block xs={12} title={t("DashboardConnections")}
                       content={<ExampleGraph />}
                       actions={<Button variant="contained" color="default">{t('See')}</Button>}
                />
                {lea&&<>
                    <Block xs={12} sm={6} md={3} title={t("DashboardCalendar")} h={120} op={0.8}
                           content={<ul className={classes.ul}>
                               <li>{t('DashboardCalendarPrivate')}</li>
                               <li>{t('DashboardCalendarService')}</li>
                           </ul>}
                           actions={<Button variant="contained" color="default">{t('Agenda')}</Button>}
                    />
                    <Block xs={12} sm={6} md={3} title={t("DashboardDirectory")} h={120} op={0.9}
                           content={<ul className={classes.ul}>
                               <li>{t('DashboardDirectoryInternal')}</li>
                               <li>{t('DashboardDirectoryNational')}</li>
                               <li>{t('DashboardDirectoryForeign')}</li>
                               <li>{t('DashboardDirectoryExperts')}</li>
                           </ul>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block xs={12} sm={6} md={3} title={t("DashboardContact")} h={120} op={0.9}
                           content={<ul className={classes.ul}>
                               <li>{t('DashboardContactVideo')}</li>
                               <li>{t('DashboardContactVoice')}</li>
                               <li>{t('DashboardContactChat')}</li>
                           </ul>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block xs={12} sm={6} md={3} title={t("DashboardDocumentation")} h={120} op={0.9}
                           content={<ul className={classes.ul}>
                               <li>{t('DashboardDocumentationNews')}</li>
                               <li>{t('DashboardDocumentationInternal')}</li>
                               <li>{t('DashboardDocumentationLegal')}</li>
                               <li>{t('DashboardDocumentationGoodPractice')}</li>
                           </ul>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block h={100} xs={12} sm={6} md={4} title={t("DashboardFormReport")} h={120} op={0.8}
                           content={<ul className={classes.ul}>
                               <li>{t('DashboardFormInternalForm')}</li>
                               <li>{t('DashboardFormTools')}</li>
                           </ul>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block h={100} xs={12} sm={6} md={4} title={t("DashboardAdministration")} h={120} op={0.8}
                           content={<ul className={classes.ul}>
                               <li>{t('DashboardAdministrationRules')}</li>
                               <li>{t('DashboardAdministrationUsers')}</li>
                           </ul>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block h={100} xs={12} sm={6} md={4} title={t("DashboardMyProfile")} h={120} op={0.8}
                           content={<ul className={classes.ul}>
                               <li>{t('DashboardMyProfilePassword')}</li>
                               <li>{t('DashboardMyProfileAbsence')}</li>
                           </ul>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                </>}

            </Grid>


        </ThemeProvider>
        <SpeedDialer classes={classes} />
    </Container>;
};

function Block({title, content, actions, xs, lg, sm, md, h, op}) {
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <Card elevation={3} style={{backgroundColor: `rgba(255,255,255,${op ? op : 100})`}}>
            <CardHeader title={title} />
            {content
                ? <CardContent style={{minHeight: h}}>
                    {content}
                </CardContent>
                : null}
            {actions
                ? <CardActions style={{display: "flex", justifyContent: "flex-end"}}>
                    {actions}
                </CardActions>
                : null}
        </Card>
    </Grid>;
}

function BlockInvestigations({xs, lg, sm, md, h}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const status = useSelector(state => state.investigations.status);
    const investigations = useSelector(selectAllInvestigations);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchInvestigations(null));
        }
    }, [status, dispatch]);
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <Fade in={true} timeout={{enter: 1000, appear:0}}>
            <Card>
                <CardHeader title={t('DashboardRecentInvestigation')} />
                <CardContent style={{minHeight: h}}>
                    <ul className={classes.ul}>
                        {investigations.map((investigation, index)=>{
                            if (index <= 1) return <li>
                                <NavLink to={`investigations/${investigation.id}`}>
                                    {investigation.name}
                                </NavLink>
                            </li>
                        })}
                    </ul>
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="secondary" to={"/investigations"} component={Link}>
                        {t("SeeAll")}
                    </Button>
                </CardActions>
            </Card>
        </Fade>
    </Grid>;
}

function BlockCriminalCases({xs, lg, sm, md, h}) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.criminalCases.status);
    const criminalCases = useSelector(selectAllCriminalCases);
    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchCriminalCases(null));
        }
    }, [status, dispatch]);
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <Fade in={true} timeout={{enter: 2000, appear: 1000, exit: 1000}}>
            <Card>
                <CardHeader title={t('DashboardRecentCases')}/>
                <CardContent style={{minHeight: h}}>
                    <ul className={classes.ul}>
                    {criminalCases.map((criminalCase, index) => {
                        if (index <= 1) return <li>
                            <NavLink to={`investigations/${criminalCase.id}`}>
                                {criminalCase.summary}
                            </NavLink>
                        </li>
                    })}
                    </ul>
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="secondary" to={"/cases"} component={Link}>
                        {t("SeeAll")}
                    </Button>
                </CardActions>
            </Card>
        </Fade>
    </Grid>;

}
function BlockTask({xs, lg, sm, md, h}) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.tasks.status);
    const tasks = useSelector(selectAllTasks);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchTasks(null));
        }
    }, [status, dispatch]);
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <Fade in={true} timeout={{enter: 2000, appear:1000, exit: 1000}}>
            <Card>
                <CardHeader title={t('DashboardIncomingTasks')} />
                <CardContent style={{minHeight: h}}>
                    <ul className={classes.ul}>
                    {tasks.map((tasks, index)=>{
                        if (index <= 1) return <li>
                            <NavLink to={`investigations/${tasks.id}`}>
                                {tasks.name}
                            </NavLink>
                        </li>
                    })}
                    </ul>
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="secondary" to={"/cases"} component={Link}>
                        {t("SeeAll")}
                    </Button>
                </CardActions>
            </Card>
        </Fade>
    </Grid>;
}


// dialer
function SpeedDialer({classes}) {

    const [dialerOpened, setDialerOpened] = useState(false);
    const history = useHistory();

    const handleOpen = () => {
        setDialerOpened(true);
    };
    const handleClick = (link) => {
        history.push(link)
        setDialerOpened(false);
    };
    const actions = [
        { icon: <ImageSearchIcon />, name: 'search', title: 'Search', to:'/identification' },
        { icon: <CollectionsIcon />, name: 'collec', title: 'Collections', to: '/collections' },
    ];
    return <SpeedDial
        ariaLabel="SpeedDial tooltip"
        open={dialerOpened}
        hidden={false}
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={()=>setDialerOpened(false)}
        onOpen={handleOpen}
    >
        {actions.map((action) => (
            <SpeedDialAction
                title={action.title}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={()=>handleClick(action.to)}
            />
        ))}
    </SpeedDial>
}

// Theme
const lightTheme = (prevision = false) => createMuiTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        /*flexGrow: 1*/
    },
    card: {

    },
    ul: {
        paddingLeft: '1rem'
    },
    create: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        height: 100
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));
export default Home;
