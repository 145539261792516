/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Switch, Route, useHistory, NavLink} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {useTranslation} from "react-i18next";

import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import {makeStyles} from "@material-ui/core/styles";

import {Box, Button, IconButton} from '@material-ui/core';

import {fetchCollectionObjects, selectObjectsByCollection} from "../../store/reducers/objectsSlice";
import {selectAllTreeCategories, selectTreeCategoryById} from "../../store/reducers/treeCategoriesSlice";
import WithRightButtons from "../Misc/WithRightButtons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import {
    AddCircle,
    Ballot,
    Bookmarks,
    DoneAll,
    RemoveCircle,
    ZoomIn,
    ZoomOut,
    ArtTrack,
    ViewList,
    ViewComfy,
    OpenWith, Edit, RemoveRedEye, OpenInNew
} from "@material-ui/icons";
import {selectDrawerVisible, setDrawer} from "../Selectors/Drawer/SelectorSlice";
import tdb from "../../services/TranslateDB";

import CollectionObjectDnD from "./DnD/CollectionObjectDnD";
import CollectionObjectsDnD from "./DnD/CollectionObjectsDnD";
import {DataGrid} from "@material-ui/data-grid";
// import { DataGridPro } from '@mui/x-data-grid-pro';
import {LinearProgress} from "@material-ui/core";
import NiceTooltip from "../Misc/CustomToolTip";
import CollectionObjectCreate from "./CollectionObjectCreate";
import CollectionObject from "./CollectionObject";
import CollectionObjectThumb from "./CollectionObjectThumb";
import {selectCollectionDisplay, setCollectionDisplay} from "../../store/reducers/preferencesSlice";
import {selectAllImages, selectImagesByObject} from "../../store/reducers/imagesSlice";
import EditIcon from "@material-ui/icons/Edit";
import EditableCategory from "../Misc/EditableCategory";

export default function CollectionObjects({collectionId, editing}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();
    const objects    = useSelector(state => selectObjectsByCollection(state, collectionId));
    const objectIds = objects.length ? objects.map(obj => obj.id) : [];

    // DISPLAY MANAGEMENT
    const display = useSelector(selectCollectionDisplay)
    const handleDisplay = (event, mode) => {
        dispatch(setCollectionDisplay(mode))
    }
    const [cellSize, setCellSize] = useState(2);
    const handleCellSize = (event, size) => setCellSize(size);

    // GROUPING MANAGEMENT
    const [grouping, setGrouping] = useState(false);
    const handleGrouping = () => setGrouping(!grouping);

    // SELECTION MANAGEMENT
    const [selected, setSelected] = useState([]);
    const handleSelect = (objectId) => {
        let newSelected;
        if (selected.indexOf(objectId) === -1) {
            newSelected = multipleSelect ? [objectId, ...selected] : [objectId]
        }
        else {
            newSelected = [...selected];
            newSelected.splice(selected.indexOf(objectId), 1)
        }
        setSelected(newSelected);
    }
    const [multipleSelect, setMultipleSelect] = useState(true);
    const handleMultipleSelect = () => setMultipleSelect(!multipleSelect);

    // SELECTOR DRAWER TOGGLE
    const selectorView = useSelector(selectDrawerVisible)
    const handleSelector = () => {
        dispatch(setDrawer(!selectorView))
    }

    // Open modal by pushing history
    const handleModalOpen = (objectId) => {
        history.push("/collections/"+collectionId+"/object/"+objectId);
    }

    // go next
    const handleModalNext = (objectId) => {
        const current = objectIds.indexOf(objectId)
        if (current === -1)return;
        const nextId = current === objectIds.length -1 ? objectIds[0] : objectIds[current+1]
        history.push("/collections/"+collectionId+"/object/"+nextId);
    }

    // go prev
    const handleModalPrev = (objectId) => {
        const current = objectIds.indexOf(objectId)
        if (current === -1)return;
        const nextId = current === 0 ? objectIds[objectIds.length -1] : objectIds[current-1]
        history.push("/collections/"+collectionId+"/object/"+nextId);
    }

    // back to collection
    const handleModalClose = () => {
        history.push("/collections/"+collectionId);
    }

    useEffect(()=>{
        dispatch(fetchCollectionObjects(collectionId));
    }, [collectionId, dispatch]);

    const handleNavigate = (index) => {
        alert(index);
    }

    // just to compact JSX
    const props = {
        collectionId: collectionId,
        editing: editing,
        cellSize: cellSize,
        display: display,
        handleModalOpen: handleModalOpen,
        handleSelect: handleSelect,
        objects: objects,
        selected: selected,
    }

    return <CardContent>
        <Grid direction="row" justify="space-between" alignItems="flex-start" container
              className={classes.zoneBar}>
            <Grid direction="row" justify="flex-start" spacing={2} item container xs>
                <Grid item>
                    <Typography variant="h5">{t('CollectionObjets')}</Typography>
                </Grid>
            </Grid>
            <Grid direction="row" spacing={2} justify="flex-end" item container xs>
                <Grid item>

                    <ToggleButtonGroup className={classes.buttonGroup}>
                        <NiceTooltip title={t("ObjectsMultipleSelection")} placement={"top-end"}>
                            <ToggleButton value={t("multipleSelect")} selected={multipleSelect} size="small" style={{height: 40}} onClick={handleMultipleSelect}>
                                <DoneAll />
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup className={classes.buttonGroup} aria-label={"display"}>
                        <NiceTooltip title={t("ObjectsGridView")} placement={"top-end"}>
                            <ToggleButton value="grid" size="small" selected={display==="grid"} style={{height: 40}} onClick={handleDisplay} aria-label={"grid"}>
                                <ViewComfy />
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("ObjectsFullView")} placement={"top-end"}>
                            <ToggleButton value="full" size="small" selected={display==="full"}  style={{height: 40}} onClick={handleDisplay} aria-label={"full"}>
                                <ArtTrack />
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("ObjectsSheetView")} placement={"top-end"}>
                            <ToggleButton value="list" size="small"  selected={display==="list"} style={{height: 40}} onClick={handleDisplay} aria-label={"sheet"}>
                                <ViewList />
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup className={classes.buttonGroup}>
                        <NiceTooltip title={t("groupedView")} placement={"top-end"}>
                            <ToggleButton value="check" selected={grouping} size="small" style={{height: 40}} onClick={handleGrouping}>
                                <Ballot />
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup className={classes.buttonGroup}>
                        <NiceTooltip title={t("ShowThesaurusPanel")} placement={"top-end"}>
                            <ToggleButton value="check" selected={selectorView} size="small" style={{height: 40}} onClick={handleSelector}>
                                <Bookmarks />
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup>
                        <ToggleButton size="small" style={{height: 40}}>
                            <ZoomOut />
                            <Slider
                                style={{width: 80}}
                                className={classes.slider}
                                defaultValue={cellSize}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                onChange={handleCellSize}
                                step={1}
                                marks
                                min={1}
                                max={5}
                            />
                            <ZoomIn />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </Grid>
        {
            grouping
                ? <CollectionObjectGrouping {...props}/>
                : <CollectionObjectSwitch {...props}/>
        }
        <Switch>
            <Route path={`/collections/${collectionId}/add`}>
                <CollectionObjectCreate handleClose={handleModalClose} collectionId={collectionId} />
            </Route>
            <Route path={`/collections/${collectionId}/object/:objectId`}>
                <CollectionObject handleClose={handleModalClose} collectionId={collectionId} next={handleModalNext} prev={handleModalPrev} />
            </Route>
        </Switch>
    </CardContent>
}

function CollectionObjectGrouping(props) {
    const {objects} = props
    const groups = {};
    objects.map((object, i) => {
        if (object.category) {
            if (!groups[object.category])groups[object.category] = [];
            groups[object.category].push(object)
        }
        else {
            if (!groups[0])groups[0] = [];
            groups[0].push(object)
        }
    });
    return <Box>
        {Object.entries(groups).map(([categoryId, groupObjects]) => {
            return <CollectionObjectsGroup {...props} key={categoryId} categoryId={categoryId} groupObjects={groupObjects} />
        })}
    </Box>;
}

function CollectionObjectsGroup(props) {
    // console.log("CollectionObjectsGroup", props)
    const classes = useStyles();
    const {editing, collectionId, categoryId, groupObjects} = props
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const [show, setShow] = useState(true);
    const handleShow = () => setShow(!show);

    const content = <>
        <WithRightButtons actions={null} className={classes.groupTitle}>
            <Button onClick={handleShow}>
                <IconButton>{show ? <RemoveCircle /> : <AddCircle />}</IconButton>
                <Typography variant={"h5"}>{category ? tdb(category.title) : "Unclassified"}</Typography>
            </Button>
        </WithRightButtons>
        {show && <CollectionObjectSwitch {...props} objects={groupObjects} className={classes.groupSection}/>}
    </>;

    return editing
        ? <CollectionObjectsDnD collectionId={collectionId} categoryId={categoryId} className={classes.groupDnD}>
            {content}
        </CollectionObjectsDnD>
        : <>{content}</>;
}

function CollectionObjectSwitch(props) {
    return props.display === "list" ? <CollectionObjectsSheet {...props} /> : <CollectionObjectsGrid {...props} />
}

let timeOut;

function CollectionObjectsGrid({objects, display, editing, cellSize, selected, collectionId, handleSelect, handleModalOpen, className}) {
    const [effect, setEffect] = useState();
    const [countDisplay, setCountDisplay] = useState(0);

    return !objects.length
        ? <LinearProgress color={"secondary"} variant={"indeterminate"} />
        : <Grid container spacing={2} wrap="wrap" justify="flex-start" className={className}>
        {objects.map((object, index) => (
            index >= countDisplay && <Grid item key={object.id}>
                {
                    (editing)
                        ? <CollectionObjectDnD collectionId={collectionId} objectId={object.id}>
                            <CollectionObjectThumb
                                objectId={object.id}
                                imageSize={cellSize}
                                editing={editing}
                                displayMode={display}
                                onAction={handleModalOpen}
                                selected={selected.indexOf(object.id) > -1}
                                onSelect={handleSelect}
                            />
                        </CollectionObjectDnD>
                        : <CollectionObjectThumb
                            objectId={object.id}
                            imageSize={cellSize}
                            editing={editing}
                            displayMode={display}
                            onAction={handleModalOpen}
                            selected={selected.indexOf(object.id) > -1}
                            onSelect={handleSelect}
                        />
                }
            </Grid>
        ))}
    </Grid>
}


function CollectionObjectsSheet({objects, display, editing, cellSize, selected, collectionId, handleSelect, handleModalOpen, className}) {
    // TODO : optimize this
    let categories = useSelector(selectAllTreeCategories);
    const images = useSelector(selectAllImages);

    const {t} = useTranslation();

    const renderImage = (params) => {
        if (!params.value)return null;
        return <img src={params.value} />
    }

    const renderOpener = (params) => {
        return <IconButton to={`/collections/${collectionId}/object/${params.value}`} component={NavLink} >
            {editing ? <Edit /> : <RemoveRedEye /> }
        </IconButton>
    }

    const handleChange = (body) => {
        console.log(body)
    }

    const renderEditCategory = (params) => {
        console.log(params);
        return <EditableCategory
            initValue={params.row.category}
            field={"categoryId"}
            onChange={handleChange}
            />
    }

    let initCols = [
        {field: "image",    headerName: "image",    width: 100, disableColumnMenu: true, renderCell: renderImage, sortable: false},
        {field: "id",       headerName: " ",         width: 50,  disableColumnMenu: true, renderCell: renderOpener, sortable: false, align:'center'},
        {field: "name",     headerName: "Name",     width: 150, disableColumnMenu: true, editable: true},
        {field: "category", headerName: "Category", width: 200, disableColumnMenu: true, editable: true, renderEditCell: renderEditCategory},
    ]
    const [fields, setFields] = useState([]);
    const [cols, setCols] = useState(initCols);
    useEffect(()=>{
        if (fields) {
            //const _cols = [...initCols, ...fields];
            setCols(initCols.concat(fields))
        }
    }, [fields])

    const [rows, setRows] = useState([]);
    useEffect(()=>{
        if (objects) {
            let _fieldNames = [];
            let _fields = [];
            let _rows = objects.map(object=>{
                let _category = categories.find(cat=>cat.id === object.category);
                let image = images.find(i=>i.object_id===object.id);
                let _row = {
                    id: object.id,
                    name: object.name,
                    image: image ? image.urls.small : null,
                    category: _category ? tdb(_category.title, _category.name) : t('Unclassified')// could be optimized
                }
                if (object.fields) {
                    Object.keys(object.fields).map(fieldName=>{
                        if (!_fieldNames.includes(fieldName)) {
                            _row["f-"+fieldName] = object.fields[fieldName];
                            _fieldNames.push(fieldName);
                            _fields.push({
                                field: "f-"+fieldName,
                                headerName: fieldName,
                                disableColumnMenu: true,
                                width: 200,
                                renderCell: renderAdditionalCol
                            })
                        }
                    })
                }
                return _row;
            })
            setRows(_rows);
            setFields(_fields)
        }
    }, [objects])

    console.log("working in CollectionObjectsSheet")

    const renderAdditionalCol = (params) => {
        if (!params.value) {
            return null;
        }
        const _type = typeof params.value;
        if (['string', 'number'].includes(_type)) {
            return params.value;
        }
        else if (_type === "object") {
            if (params.value.title)
                return tdb(params.value.title);
            else if (params.value.title)
                return params.value.value;
            else
                return "object"
        }
        return "n/c";
    }



    return <div style={{height: 400}}>
        <DataGrid
            resizable={true}
            density={"compact"}
            columns={cols}
            rows={rows}
            //editMode={'cell'}
            checkboxSelection={editing}
            //isCellEditable={editing}
            disableSelectionOnClick
        />
    </div>
}




const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    buttonGroup: {
        marginRight: theme.spacing(2)
    },
    groupTitle: {
        //marginTop: ".5rem",
        //marginBottom: ".5rem",
    },
    groupSection: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    groupDnd: {
        paddingLeft: theme.spacing(1)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    slider: {
        color: 'rgba(0,0,0,1)',
        marginLeft: 3,
        marginRight: 3,
    }
}));