import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
// MATERIAL
import {
    Paper, IconButton, Button, Switch, Grid, Box, Typography, Dialog, DialogTitle, DialogActions, DialogContent, List, ListItem, ListItemIcon, ListItemText, TextField, Menu, MenuItem, Card, CardHeader, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup
} from "@material-ui/core";
import {
    Cancel, Delete, DeleteForever, DeleteOutlineRounded, MoreVert as MoreVertIcon, Collections as CollectionsIcon, NavigateNext, NavigateBefore, SkipNext, Close, ArrowDropDown
} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
// SLICES
import Image from "material-ui-image";
import {selectObjectById} from "../../store/reducers/objectsSlice";
import {selectImagesByObject} from "../../store/reducers/imagesSlice";
import {selectTreeCategoryById} from "../../store/reducers/treeCategoriesSlice";
// CUSTOM
import tdb from "../../services/TranslateDB";
import clsx from "clsx";

// ---------------------------------------------------------------------------------------------------------------------
// OBJECT THUMBNAIL
export default function CollectionObjectThumb({objectId, imageSize, displayMode, editing, onAction, selected, onSelect}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();
    const object = useSelector(state => selectObjectById(state, objectId));
    const images = useSelector(state => selectImagesByObject(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object.category));

    const [currentImage, setCurrentImage] = useState(0);
    const handleCurrentImage = () => {
        setCurrentImage((currentImage + 1) % images.length);
        return false;
    }

    // const nothing = () => {}
    // const clickAction = editing ? nothing : action;
    // const doubleClickAction = editing ? action : nothing;

    const getMappedSize = () => {
        return imageSize * 25 + 50;
    };

    return (
        <>
            <Paper className={clsx(classes.image, {[classes.selected]:selected})} elevation={2}>
                <div
                    onClick={()=>onSelect(objectId)}
                    onDoubleClick={onAction ? () => onAction(objectId) : () => {}}
                >
                    <Typography style={{width: getMappedSize(), height: '3rem', fontSize: ".9rem"}}>
                        {category ? tdb(category.title, category.name) : t('Unclassified')}
                        {selected && "!"}
                    </Typography>
                    <span>
                        {images.length
                            ? <Image
                                alt=""
                                src={images[currentImage].urls.medium ? images[currentImage].urls.medium : "error"}
                                draggable={false}
                                className={classes.media}
                                style={{width: getMappedSize(), height: getMappedSize()}}
                                onDoubleClick={onAction ? () => onAction(objectId) : () => {}}
                                onClick={handleCurrentImage}
                            />
                            : <div
                                className={classes.media}
                                style={{width: getMappedSize(), height: getMappedSize()}}
                            >
                            </div>
                        }
                    </span>
                </div>
            </Paper>
        </>
    );
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    image: {
        borderStyle: 'hash',
        borderColor: theme.palette.primary.contrastText,
        borderWidth: 1,
        padding: theme.spacing(1),
    },
    selected: {
        backgroundColor: "#cccccc"
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    }
}));

