import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation}     from 'react-i18next';
import {NavLink, Route, Switch as SwitchRoute, useHistory} from "react-router-dom";

import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import CollectionImages from './CollectionImages.jsx';
import CollectionObjects from './CollectionObjects.jsx';
import CollectionObjectsDnD from "./DnD/CollectionObjectsDnD.jsx";
import CollectionsIcon from "@material-ui/icons/Collections";

import {fetchCollectionById, selectCollectionById, updateCollection} from "../../store/reducers/collectionsSlice";
import {fetchTreeCategories, selectAllTreeCategories} from "../../store/reducers/treeCategoriesSlice";

import {Box, Grid, LinearProgress, Tooltip} from "@material-ui/core";
import {fetchTreeFields} from "../../store/reducers/treeFieldsSlice";
import {fetchFieldTypes, selectAllFieldTypes} from "../../store/reducers/fieldTypesSlice";
import {fetchTreeThesauruses} from "../../store/reducers/treeThesaurusSlice";
import EditableText from "../Misc/EditableText";
import tdb from "../../services/TranslateDB";
import Typography from "@material-ui/core/Typography";

import {ObjectData, ObjectField, ObjectThesaurus} from "./CollectionFields";
import {fetchCollectionTypes, selectCollectionTypeById} from "../../store/reducers/collectionTypesSlice";
import CardContent from "@material-ui/core/CardContent";
import {
    fetchCollectionTypeFields, selectCollectionFieldsOfType
} from "../../store/reducers/collectionTypesFieldsSlice";
import CollectionImport from "./CollectionImport";
import {getCurrentUser, isGranted} from "../../store/reducers/currentUserSlice";
import {Add, Camera, Create, Edit, MenuOpen, PhotoAlbum} from "@material-ui/icons";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import {selectEditMode} from "../Selectors/Drawer/SelectorSlice";
import NiceTooltip from "../Misc/CustomToolTip";


const Collection = ({collectionId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();

    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const categories = useSelector(selectAllTreeCategories);

    const statusCategories = useSelector(state => state.treeCategories.status);
    const statusThesauruses = useSelector(state => state.treeThesauruses.status);
    const statusFields = useSelector(state => state.treeFields.status);
    const statusTypes = useSelector(state => state.fieldTypes.status);
    const statusCollectionTypes = useSelector(state => state.collectionTypes.status);
    const statusCollectionTypeFields = useSelector(state => state.collectionTypeFields.status);

    const currentUser = useSelector(getCurrentUser);
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);

    const editMode = useSelector(selectEditMode);
    const setEditMode = (value) => {dispatch(setEditMode(value))}

    const [editing, setEditing] = useState(false);
    const [images, setImages] = useState(false);

    const handleCloseImport = () => history.push(`/collections/${collectionId}/`);

    // first time, we need to load all collection in case we get directly on this page
    // TODO : think about do this from slice
    useEffect(() => {if (!collection) {                           dispatch(fetchCollectionById(collectionId));}},   [dispatch, collection]);
    useEffect(() => {if (statusCategories === 'idle')             dispatch(fetchTreeCategories(null));},            [dispatch, statusCategories])
    useEffect(() => {if (statusThesauruses === 'idle')            dispatch(fetchTreeThesauruses(null));},           [dispatch, statusThesauruses])
    useEffect(() => {if (statusFields === 'idle')                 dispatch(fetchTreeFields(null));},                [dispatch, statusFields])
    useEffect(() => {if (statusTypes === 'idle')                  dispatch(fetchFieldTypes(null));},                [dispatch, statusTypes])
    useEffect(() => {if (statusCollectionTypes === 'idle')        dispatch(fetchCollectionTypes(null));},           [dispatch, statusCollectionTypes])
    useEffect(() => {if (statusCollectionTypeFields === 'idle')   dispatch(fetchCollectionTypeFields(null));},      [dispatch, statusCollectionTypeFields])

    const [createMode, setCreateMode] = useState();

    const [ready, setReady] = useState(false);
    useEffect(()=>{
        if (collection && ![
            statusCategories,
            statusThesauruses,
            statusFields,
            statusTypes,
            statusCollectionTypes,
            statusCollectionTypeFields
        ].find(status => status !== 'succeeded')) {
            setReady(true)
        }
    }, [
        collection,
        statusCategories,
        statusThesauruses,
        statusFields,
        statusTypes,
        statusCollectionTypes,
        statusCollectionTypeFields,
    ])

    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(updateCollection({
            collectionId: collectionId,
            body: body
        }))
    }

    const handleChangeField = (value, field) =>  {
        let body = {
            fields:{}
        };
        body.fields[field] = value;
        dispatch(updateCollection({
            collectionId: collectionId,
            body: body
        }))
    }

    const loadingControl = (title, status, exclude) => {
        return status === exclude ? null : <li>{`${title} : ${status}`}</li>
    }

    if (!ready) {
        return <Card elevation={3}>
            <CardContent>
                <CardHeader title={"Collection"} />
                <CardContent>
                    <LinearProgress color={"secondary"} variant={"indeterminate"} />
                    <ul>
                        {loadingControl('Collection', !!collection, true)}
                        {loadingControl('Categories', statusCategories, 'succeeded')}
                        {loadingControl('Thesauruses', statusThesauruses, 'succeeded')}
                        {loadingControl('Fields', statusFields, 'succeeded')}
                        {loadingControl('Types', statusTypes, 'succeeded')}
                        {loadingControl('CollectionTypes', statusCollectionTypes, 'succeeded')}
                        {loadingControl('CollectionTypeFields', statusCollectionTypeFields, 'succeeded')}
                    </ul>
                </CardContent>
            </CardContent>
        </Card>
    }

    return <Card elevation={3}>
        <CardHeader
            avatar={<CollectionsIcon fontSize="large"/>}
            title={ editing
                ? <EditableText
                    initValue={collection.name}
                    onChange={handleChange}
                    field='name'
                    variant='h5'
                    inputClasses={classes.h5input}
                />
                : <Typography variant="h5">{collection.name} -</Typography>
            }
            action={<>
                <Box style={{flexDirection: 'row'}}>
                    <ToggleButtonGroup>
                        <NiceTooltip title={t("handleUploadImages")} placement={"bottom-end"}>
                            <ToggleButton selected={images} onClick={()=>setImages(e=>!e)}>
                                <AddAPhotoIcon />
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("toogleEditMode")} placement={"bottom-end"}>
                            <ToggleButton selected={editing} onClick={()=>setEditing(e=>!e)}>
                                <Edit />
                                <Typography style={{marginLeft:5}}>Edit</Typography>
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                </Box>
            </>}
        >
        </CardHeader>
        <CardContent>
            <CollectionFields collectionId={collectionId} editing={editing} handleFieldUpdate={handleChangeField} />
            <SwitchRoute>
                <Route path={`/collections/${collectionId}/import/`}>
                    <CollectionImport open={true} onClose={handleCloseImport} collectionId={collectionId} />
                </Route>
            </SwitchRoute>
        </CardContent>
        {images &&  <CollectionImages  collectionId={collectionId} editing={editing} />}
        {editing
            ?   <CollectionObjectsDnD collectionId={collectionId}>
                    <CollectionObjects collectionId={collectionId} editing={editing} />
                </CollectionObjectsDnD>
            :   <CollectionObjects collectionId={collectionId} editing={editing} />}
        <CardActions>
            {
                !prevision && <>
                    <Button
                        variant={"contained"}>
                        {t("CreateQueryFromCollection")}
                    </Button>
                </>
            }
            <Button variant={"contained"} component={NavLink} to={`/collections/${collectionId}/import/`}>
                {t("ImportList")}
            </Button>
            <Button startIcon={<Add />} title={t('Create simple object')} variant={"contained"} color={"secondary"} to={`/collections/${collectionId}/add`} component={NavLink} style={{margin:'10px'}}>
                {t('CreateObject')}
            </Button>
        </CardActions>
    </Card>;
};
export default Collection;

function CollectionFields({collectionId, editing, handleFieldUpdate}) {
    const {t} = useTranslation();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const collectionType = useSelector(state => selectCollectionTypeById(state, collection.collection_type));
    const collectionTypeFields = useSelector((state) => selectCollectionFieldsOfType(state, collectionType.id));
    const types = useSelector(selectAllFieldTypes);
    return <Grid container spacing={0}>
        <>
            {collectionTypeFields.map((field, i) => {
                const type = types.find(type => type.id === field.tree_field_type_id);
                return <Grid lg={6} key={field.name} item>
                    {(type && type.is_thesaurus)
                        ? <ObjectThesaurus
                            key={`${field.name}-${i}`}
                            field={field}
                            values={collection.fields}
                            editing={editing}
                            update={handleFieldUpdate}
                            type={type}
                            cols={[2,10]}
                        />
                        : <ObjectField
                            key={`${field.name}-${i}`}
                            field={field}
                            values={collection.fields}
                            editing={editing}
                            update={handleFieldUpdate}
                            type={type}
                            cols={[2,10]}
                        />
                    }
                </Grid>
            })}
        </>
    </Grid>;
}



const lightTheme = (prevision = false) => createMuiTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette),
    typography: {
        h1: {
            fontSize: "2.8rem"
        },
        h2: {
            fontSize: "2.4rem"
        },
        h3: {
            fontSize: "2.1rem"
        },
        h4: {
            fontSize: "1.8rem"
        },
        h5: {
            fontSize: "1.5rem"
        },
        h6: {
            fontSize: "1.2rem"
        }
    }
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    images: {
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#ffcccc"

    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),

    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    bordered: {
        width: '50%'
    },
    slider: {
        color: 'rgba(0,0,0,1)',
    },
    collection: {
        padding: theme.spacing(5)
    },
    navLink: {
        color: theme.palette.primary.contrastText,
        textDecoration: 'inherit'
    }
}));

// export default withStyles(useStyles)(Collection);
