import React, {useState, useEffect, createRef, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import {useTranslation} from "react-i18next";
import {launchSearch, searchInit} from "../../store/reducers/searchSlice";


import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import client_ai from '../../api/client_ai';
import client from '../../api/client';


const ObjectCreate = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileInput = createRef();
    const {t, i18n} = useTranslation();
    const form = useRef(null)

    const [object_name, setObjectName] = useState(null);
    const [object_type, setObjectType] = useState(null);
    const [object_material, setObjectMaterial] = useState(null);
    const [object_origin, setObjectOrigin] = useState(null);
    const [object_period, setObjectPeriod] = useState(null);

    const handleObjectName = (event) => setObjectName(event.target.value);
    const handleObjectType = (event) => setObjectName(event.target.value);
    const handleObjectMaterial = (event) => setObjectName(event.target.value);
    const handleObjectOrigin = (event) => setObjectName(event.target.value);
    const handleObjectPeriod = (event) => setObjectName(event.target.value);

    const status = useSelector(state => state.search.status);
    const search = useSelector(state => state.search.status ==="success" ? state.search.search.search : null);

    FileReader.prototype.setFile = function(file) {
        this.file = file;
        return this;
    };
    const [image, setImage] = useState(null);
    const handleGetImage = () => {
        if (fileInput.current.files.length === 1) {
            const file = fileInput.current.files[0];
            const reader = new FileReader().setFile(file);
            reader.onload = function(event) {
                var data = {
                    data: 'data:'+this.file.type+';base64,'+window.btoa(this.result),
                    name: this.file.name,
                }
                setImage(data);
                data['indexes'] = ['redListInt']
                console.log(data)
                dispatch(launchSearch(data));
            };
            reader.readAsBinaryString(file);
        }
    };
    const handleOpenFile = () => {
        fileInput.current.click();
    }
    const removeBackground = () => {
      var data = new FormData(form.current)
      client.post("/remove_background", image).then(
        (response)=>{
          setImage(state => ({...state, url: response.url}))
        }
      )
    }
    const getImage = () => {
      if ('url' in image) return image.url;
      if ('data' in image) return image.data;
    }

    const createObject = () => {

    }



    return <Card>
        <CardHeader title={t('CreateNewObject')} />
        <CardContent justify = "center">
            {/* TODO: form here */}
            <Grid container>
              <Grid display="flex" md={search ? 2 : 4} sm={0} xs={12}>
              </Grid>
              <Grid display="flex" md={4} sm={6} xs={12}>
              <Grid container spacing={2} justify={"center"}>

                      {
                          image
                              ?   <img src={getImage()} style={{ width: '100%', height: 'auto'}}   />
                              :   <Box display="flex" alignItems={"stretch"}  style={{cursor: 'pointer', border: '2px dashed #ccc', height: 200, width: "100%", backgroundColor: "#e0e0e0"}} onClick={handleOpenFile}>
                                <AddAPhotoIcon style={{ margin:' 0 auto', top: '62px', position: 'relative', fontSize: '65px', color: '#999'}} />
                              </Box>
                      }
                  </Grid>
                <Grid container spacing={2} justify={"center"}>
                      {!image
                          ?   <Button style={{ display: 'none'}}variant="contained" color="secondary" type="file" component='label' style={{margin:"15px"}} >
                                  <input ref={fileInput} name="file" onChange={handleGetImage} type="file" style={{display:"none"}} />
                                  {t('ChoosePicture')}
                              </Button>
                          :   <><Button variant="contained" color="default" startIcon={<AddAPhotoIcon />} type="file" component='label' style={{margin:"15px"}}>
                                  <input ref={fileInput} name="file" onChange={handleGetImage} type="file" style={{display:"none"}} />
                                  {t('ChangePicture')}
                              </Button>
                              <Button variant="contained" color="default" startIcon={<AddAPhotoIcon />} onClick={removeBackground} component='label' style={{margin:"15px"}}>
                                {t('RemoveBackground')}
                              </Button></>
                      }
                </Grid>
                <Grid container spacing={2} justify={"center"}>
                    <h3>{t('ObjectSettings')}</h3>
                      <TextField
                          id="object_name"
                          label={t('ObjectName')}
                          type="text"
                          variant="outlined"
                          className={classes.input}
                          value={ object_name || "" }
                          onChange={handleObjectName}
                      />
                      <TextField
                          id="object_type"
                          label={t('ObjectType')}
                          type="text"
                          variant="outlined"
                          className={classes.input}
                          value={ object_type || "" }
                          onChange={handleObjectType}
                      />
                      <TextField
                          id="object_material"
                          label={t('ObjectMaterial')}
                          type="text"
                          variant="outlined"
                          className={classes.input}
                          value={ object_material || "" }
                          onChange={handleObjectMaterial}
                      />
                      <TextField
                          id="object_period"
                          label={t('ObjectPeriod')}
                          type="text"
                          variant="outlined"
                          className={classes.input}
                          value={ object_material || "" }
                          onChange={handleObjectPeriod}
                      />
                      <TextField
                          id="object_origin"
                          label={t('ObjectOrigin')}
                          type="text"
                          variant="outlined"
                          className={classes.input}
                          value={ object_material || "" }
                          onChange={handleObjectOrigin}
                      />
                      <Divider variant="middle" />
                  </Grid>
                </Grid>
                {search ?
                  <Grid md={4} sm={6} xs={12} item  style={{padding:"0px 62px", position:"relative", top:"-20px"}}>
                    <h4>{t('SimilarObjectFound')}</h4>
                    <i></i>
                    <Grid container spacing={2} justify={"center"}>
                          {<img src={search.redListInt[0].img} style={{ width: '100%', height: 'auto'}}   />}
                          <div class="data_cat" style={{width: "100%",padding: "10px"}}><b>{t('ObjectName')} :</b> <Chip label={search.redListInt[0].data.keyTerm} /></div>
                          <div class="data_cat" style={{width: "100%",padding: "10px"}}><b>{t('ObjectType')} :</b> <Chip label={search.redListInt[0].data.type} /></div>
                          <div class="data_cat" style={{width: "100%",padding: "10px"}}><b>{t('ObjectMaterial')} :</b>
                          {search.redListInt[0].data.material.split(',').map((val, index)=>{
                            return <Chip label={val} />
                          })
                          }
                          </div>
                          <div class="data_cat" style={{width: "100%",padding: "10px"}}><b>{t('ObjectOrigin')} :</b> <Chip label={search.redListInt[0].data.estimatedOrigin}/></div>
                    </Grid>
                  </Grid>
                : null}
            </Grid>
        </CardContent>
        <CardActions style={{justifyContent: "center"}}>
            <Button onClick={createObject} color="secondary" variant="contained">
                {t('CreateObject')}
            </Button>
        </CardActions>
   </Card>;
};

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        marginTop: ".5rem",
        width: '100%'
    }
}));

export default ObjectCreate;
