import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip, Typography} from "@material-ui/core";
import {ToggleButtonGroup} from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {AccountTree, Search, UnfoldLess, UnfoldMore} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {selectPathOfCategory, selectTreeCategoryById} from "../../../store/reducers/treeCategoriesSlice";
import tdb from "../../../services/TranslateDB";
import Grid from "@material-ui/core/Grid";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {selectFieldsByCategory, selectTreeFieldById} from "../../../store/reducers/treeFieldsSlice";
import {selectFieldTypeById} from "../../../store/reducers/fieldTypesSlice";
import CodeIcon from "@material-ui/icons/Code";
import {selectSubThesaurus, selectTreeThesaurusById} from "../../../store/reducers/treeThesaurusSlice";
import TreeItem from "@material-ui/lab/TreeItem";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import {
    addFieldsOpened,
    addThesaurusOpened, removeFieldsOpened, removeThesaurusOpened,
    selectCategorySelected, selectFieldsOpened,
    selectFieldSelected,
    selectFilterNonThesaurus, selectThesaurusOpened,
    setFieldSelected, setFilterNonThesaurus, setThesaurusOpened
} from "./SelectorSlice";
import NiceTooltip from "../../Misc/CustomToolTip";

// Get selected category
export default function SelectorFields () {
    const categorySelected = useSelector(selectCategorySelected)
    return categorySelected
        ? <SelectorsCategoryFields categoryId={categorySelected} />
        : null
}

// display path and current category field panels
function SelectorsCategoryFields({categoryId}) {
    const classes = useStyles();
    const path = useSelector(state => selectPathOfCategory(state, categoryId));
    return <div className={classes.panelContainer}>
        <CategoryFieldsPanel categoryId={categoryId} />
        {path.map((parentId, index)=>{
            return <CategoryFieldsPanel categoryId={parentId} />
        })}
    </div>
}

// panel of category fields
function CategoryFieldsPanel({categoryId}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showSearch, setShowSearch] = useState();
    const category = useSelector(state => selectTreeCategoryById(state, categoryId))
    const fields = useSelector(state => selectFieldsByCategory(state, categoryId));
    const filterNonThesaurus = useSelector(selectFilterNonThesaurus);
    const handleFilterNonThesaurus = () => {dispatch(setFilterNonThesaurus(!filterNonThesaurus))}
    return fields.length === 0 ? null : <div className={classes.panel}>
        <div className={classes.panelBar}>
            <Typography>{tdb(category.title)}</Typography>
            <ToggleButtonGroup>
                <NiceTooltip title={t('ShowOnlyThesaurus')} placement={"top-end"} arrow>
                    <ToggleButton value="filter" selected={filterNonThesaurus} style={{width: 30, height:30}} size="small" onClick={handleFilterNonThesaurus}>
                        <AccountTree fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
                <NiceTooltip title={t('Search')} placement={"top-end"} arrow>
                    <ToggleButton value="search" selected={showSearch} style={{width: 30, height:30}} size="small" onClick={()=>setShowSearch(s=>!s)}>
                        <Search fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
            </ToggleButtonGroup>
        </div>
        {fields.map((field, index)=>{
            return <Field fieldId={field.id} />
        })}
    </div>
}

function Field({fieldId}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const field = useSelector(state => selectTreeFieldById(state, fieldId));
    const type = useSelector(state => selectFieldTypeById(state, field.tree_field_type_id));
    const filterNonThesaurus = useSelector(selectFilterNonThesaurus);
    const fieldsOpened = useSelector(selectFieldsOpened)
    if (fieldId === '1ec01acd-4cf2-6d1a-ba5c-21de6d9a2aeb') {
        return null;
    }
    // () => select(field.tree_thesaurus_id)
    const handleSelect = () => {
        dispatch(setFieldSelected(fieldId));
        if (type && field && type.is_thesaurus) {
            if (fieldsOpened.includes(field.id)) {
                dispatch(removeFieldsOpened(field.id))
            }
            else {
                dispatch(addFieldsOpened(field.id))
            }
        }
    }
    if (filterNonThesaurus && (!type || !type.is_thesaurus))return null;
    return <Grid container spacing={0} onClick={handleSelect} className={classes.field}>
        <Grid item xs={9} className={classes.labelRoot}>
            <CodeIcon className={classes.labelIcon} />
            <Typography className={classes.labelText}>
                {tdb(field.title, field.name)}
            </Typography>
        </Grid>
        {
            type.is_thesaurus
                ? <Grid item xs={3}>{type.name}</Grid>
                : <Grid item xs={3}>{type.name}</Grid>
        }

    </Grid>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flexDirection: "column-reverse"
    },
    panel: {
        borderBottom: '1px solid #ccc',
        paddingTop:3,
        paddingBottom:3,
        fontSize: 12
    },
    panelBar: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom:1,
    },
    panelInput: {
        //border:,
        border: '1px solid #666',
        borderRadius: 4,
        padding: 0,
        '& input': {
            fontSize: 12,
        }
    },
    search: {
        backgroundColor: 'black'
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        //marginRight: theme.spacing(1),
        height: "1rem",
        width: "1rem",
        color: "#999"
    },
    labelText: {
        flexGrow: 1,
        fontSize: 12
    },
    field: {
        //color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    fieldSelected: {
        backgroundColor: '#ccc',
    },
    categories: {
        height: '100%',
        overflow: 'auto'
    },
    paper: {
        height: 100,
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));