import {
    createSlice
} from '@reduxjs/toolkit';

const initialState = {
    collectionDisplay: 'grid'
};

const preferencesSlice = createSlice({
    name: 'preferencesSlice',
    initialState,
    reducers: {
        setCollectionDisplay(state, action) {
            state.collectionDisplay = action.payload;
        },
    }
});

export default preferencesSlice.reducer

export const {
    setCollectionDisplay
} = preferencesSlice.actions;

export const selectCollectionDisplay =          (state) => state.preferences.collectionDisplay;
