import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
    current
} from '@reduxjs/toolkit';

import client from '../../api/client';

export const tabNames = {
    // police_test: {
    //     label: "Police Test",
    //     groupBy:null,
    //     position:1
    // },
    // looted: {
    //     label: "Objets spoliés",
    //     groupBy:null,
    //     position:1
    // },
    police:{
        label: "Police",
        groupBy:null,
        position:2
    },
    ecommerce: {
        label: "Sites Marchands",
        groupBy:null,
        position:2
    },
    positive_database:       {
        label: "Bases Positives",
        groupBy: "datasetid",
        groupName: "Base",
        position:2
    },
    // redLists :    {
    //     label: "Red List Old",
    //     groupBy:"red_list",
    //     groupName: "Red List",
    //     exclude: ['img', 'image', 'id', 'dist']
    // },
    redListInt :  {
        label: "Red List",
        groupBy:"provenance",
        groupName: "provenance",
        position:2
    },
    smartBrowser: {
        label: "Smart Browser",
        groupBy:"joinedCategories",
        groupName:"Categorie",
        position:2
    },
};

// adding image
export const launchSearch = createAsyncThunk(
    'survey/launchSearch',
    async (parameters) => {
        const body = {
            fullImage: parameters.data,
            precision: {value:1},
            searchText: parameters.searchText,
            indexes: parameters.indexes ? parameters.indexes : null
        };
        // const response = await client.post('/identification/json', body);
        const response = await client.post('/identification', body);
        var result = {};
        var tabsOrder = [
        // 'police_test',
        //'looted',
        'police',
        'ecommerce',
        'positive_database',
        'redListInt',
        'smartBrowser'];
        tabsOrder.forEach(function (item, index) {
          result[item] = response.data.search[item]
        });
        var bestMatch = null;
        var dist = 1;
        for(var key in response.data.search){
          if (response.data.search[key].length>0 && response.data.search[key][0]["dist"] < dist){
            dist = response.data.search[key][0]["dist"]
            bestMatch = key
          }
        };
        response.data.search = result
        response.data.bestMatch = bestMatch
        //console.log(response.data)
        return response.data;
    });

// Creating the slice
const searchSlice = createSlice({
    name: 'search',
    initialState: {
        status:'idle',
        search: null
    },
    reducers: {
        searchInit(state) {
            state.status = 'idle';
            state.search= null;
        },
    },
    extraReducers: {
        [launchSearch.pending]: (state, action) => {
            state.status = 'loading';
        },
        [launchSearch.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [launchSearch.fulfilled]: (state, action) => {
            state.status = 'success';
            state.search = action.payload
        },
    },
});

export const {searchInit} = searchSlice.actions;
export default searchSlice.reducer

export const selectSearch = (state) => {
    return state.search
};
