import React, {useState, useEffect, createRef} from "react";
import { useSelector, useDispatch } from "react-redux";

import {useTranslation} from "react-i18next";
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import CodeIcon from '@material-ui/icons/Code';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

import Container from "@material-ui/core/Container";

import LabelIcon from '@material-ui/icons/Label';

import tdb from "../../services/TranslateDB";

import {
    fetchTreeCategories,
    selectTreeCategoryById,
    selectRootCategories,
    selectSubCategories,
    selectPathOfCategory
} from "../../store/reducers/treeCategoriesSlice";
import {fetchTreeFields, selectFieldsByCategory, selectTreeFieldById} from "../../store/reducers/treeFieldsSlice";
import {fetchFieldTypes, selectFieldTypeById} from "../../store/reducers/fieldTypesSlice";
import {
    fetchTreeThesauruses,
    selectSubThesaurus,
    selectTreeThesaurusById
} from "../../store/reducers/treeThesaurusSlice";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import {getCurrentUser} from "../../store/reducers/currentUserSlice";


const TreeViewer = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    const statusCategories = useSelector(state => state.treeCategories.status);
    const statusThesauruses = useSelector(state => state.treeThesauruses.status);
    const statusFields = useSelector(state => state.treeFields.status);
    const statusTypes = useSelector(state => state.fieldTypes.status);
    const rootCategories = useSelector(selectRootCategories);
    const currentUser = useSelector(getCurrentUser);

    const [expanded, setExpanded] = useState([1]);
    const [selectedCategory, selectCategory] = useState(false);

    useEffect(()=>{
        if (selectedCategory) {
            //console.log(selectedCategory);
        }
    }, [selectedCategory])

    const handleToggle = (event, nodeIds) => setExpanded(nodeIds);
    const handleSelectCategory = (event, nodeIds) => selectCategory(nodeIds);

    useEffect(()=>{
        if (statusCategories === 'idle') dispatch(fetchTreeCategories(null));
        if (statusThesauruses === 'idle') dispatch(fetchTreeThesauruses(null));
        if (statusFields === 'idle') dispatch(fetchTreeFields(null));
        if (statusTypes === 'idle') dispatch(fetchFieldTypes(null));
    }, [statusCategories, statusThesauruses, statusFields, dispatch]);

    if (statusCategories !== "succeeded") {
        return <div>Loading {statusCategories}</div>;
    }

    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Card elevation={2}>
                <CardHeader title={"Thesaurus Explorer"} />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={3} className={classes.categories}>
                            <TreeView
                                className={classes.tree}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                expanded={expanded}
                                selected={selectedCategory}
                                onNodeToggle={handleToggle}
                                onNodeSelect={handleSelectCategory}
                            >
                                {rootCategories.map((rootCategory, index) => {
                                    return <Category index={index} category={rootCategory} categoryId={rootCategory.id} />
                                })}
                            </TreeView>
                        </Grid>
                        <Grid item xs={9}>
                            {selectedCategory && <InheritedFields categoryId={selectedCategory} />}
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions />
            </Card>
        </ThemeProvider>
    </Container>;
};

function Category({category, categoryId, index}) {
    // TODO : use categoryId here
    const classes = useStyles();
    const subCategories = useSelector(state => selectSubCategories(state, category.id));
    const fields = useSelector(state => selectFieldsByCategory(state, category.id));
    return <TreeItem
        nodeId={category.id}
        label={
            <div className={classes.labelRoot}>
                <LabelIcon className={classes.labelIcon} />
                <Typography className={classes.labelText}>{tdb(category.title, category.name)}</Typography>
                {
                    fields.length ? <Typography variant="caption">{fields.length}</Typography> : null
                }
            </div>
        }

    >
        {subCategories.map((subCategory, index) => {
            return <Category category={subCategory} index={index} />
        })}
    </TreeItem>
}

function InheritedFields({categoryId}) {
    const classes = useStyles();
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const path = useSelector(state => selectPathOfCategory(state, categoryId));
    const [selectedThesaurus, selectThesaurus] = React.useState(null);
    // const subThesauruses = useSelector(state => selectSubThesaurus(state, selectedThesaurus));
    const handleSelectThesaurus = (fieldId) => selectThesaurus(fieldId);
    const handleResetThesaurus = (fieldId) => selectThesaurus(null);

    if (!category)return null;
    return <Card elevation={2}>
        <CardHeader title={<Typography variant={"h4"}>{tdb(category.title, category.name)}</Typography>} />
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <CategoryFields categoryId={categoryId} select={handleSelectThesaurus} thesaurus={selectedThesaurus} />
                {path.map((parentId, index)=>{
                    return <CategoryFields categoryId={parentId} select={handleSelectThesaurus} thesaurus={selectedThesaurus} />
                })}
            </Grid>
            <Grid item xs={6}>
                {selectedThesaurus ?
                    <CardContent>
                        <TreeView
                            className={classes.tree}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                            defaultExpanded={['thes'+selectedThesaurus, 0]}
                        >
                            <Thesaurus thesaurusId={selectedThesaurus} index={0} />
                        </TreeView>
                    </CardContent>: null}
            </Grid>
        </Grid>
    </Card>;
}

function CategoryFields({categoryId, select, thesaurus}) {
    const fields = useSelector(state => selectFieldsByCategory(state, categoryId));
    const {t} = useTranslation();
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    if (!fields.length)return null;
    return <CardContent>
        <Typography variant={"h6"}>{t('FieldsOf')}&nbsp;{tdb(category.title, category.name)}</Typography>
        {fields.map((field, index)=>{
            return <Field fieldId={field.id} select={select} thesaurus={thesaurus} />
        })}
    </CardContent>;
}

function Field({fieldId, select, thesaurus}) {
    const field = useSelector(state => selectTreeFieldById(state, fieldId));
    console.log("field", field);
    const type = useSelector(state => selectFieldTypeById(state, field.tree_field_type_id));
    const classes = useStyles();
    if (fieldId == '1ec01acd-4cf2-6d1a-ba5c-21de6d9a2aeb') {
        return null;
    }

    return <Grid container spacing={0}
                 // style={{backgroundColor: (thesaurus && thesaurus == field.thesaurus) ? '#ccc' : 'transparent' }}
                 onClick={() => select(field.tree_thesaurus_id)} className={(thesaurus && thesaurus === field.tree_thesaurus_id) ? classes.fieldSelected : classes.field}
    >
        <Grid item xs={9} className={classes.labelRoot}>
            <CodeIcon className={classes.labelIcon} />
            <Typography className={classes.labelText}>
                {tdb(field.title, field.name)}
            </Typography>
        </Grid>
        {
            type.is_thesaurus
                ? <Grid item xs={3}>{type.name}</Grid>
                : <Grid item xs={3}>{type.name}</Grid>
        }

    </Grid>;
}

function Thesaurus({thesaurusId, index}) {
    const thesaurus = useSelector(state => selectTreeThesaurusById(state, thesaurusId));
    const subThesauruses = useSelector(state => selectSubThesaurus(state, thesaurusId));
    const classes = useStyles();
    return <TreeItem
        nodeId={'thes'+thesaurus.id}
        label={
            <div className={classes.labelRoot}>
                <LabelImportantIcon className={classes.labelIcon} />
                <Typography className={classes.labelText}>
                    {tdb(thesaurus.title, thesaurus.name)}
                </Typography>
            </div>

        }
    >
        {subThesauruses.map((subThesaurus, index) => {
            return <Thesaurus thesaurusId={subThesaurus.id} index={index} />
        })}
    </TreeItem>
}

// Theme
const lightTheme = (prevision = false) => createMuiTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette),
    typography: {
        fontSize: 12
    }
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        color: "#999"
    },
    labelText: {
        flexGrow: 1
    },
    field: {
        //color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    fieldSelected: {
        backgroundColor: '#ccc',
    },
    categories: {
        height: '100%',
        overflow: 'auto'
    },
    paper: {
        height: 100,
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));

export default TreeViewer;