import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import {makeStyles, createMuiTheme} from "@material-ui/core/styles";
import { NavLink} from "react-router-dom";

import { DataGrid } from '@material-ui/data-grid';

import Typography from "@material-ui/core/Typography";
import MaterialTable from 'material-table';

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import {formatDataAsCols} from "../../services/dataGridTools";

import {selectAllInvestigations, fetchInvestigations, createInvestigation} from "../../store/reducers/investigationsSlice";
import {useTranslation} from "react-i18next";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, TextField} from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

export default function InvestigationList() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const status = useSelector(state => state.investigations.status);
    const investigations = useSelector(selectAllInvestigations);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchInvestigations(null));
        }
    }, [status, dispatch]);

    const [openCreate, setOpenCreate] = useState(false);
    const handleOpenCreate = () => setOpenCreate(true);
    const handleCloseCreate = () => setOpenCreate(false);

    // TODO: choose columns
    const columns = [
        {field: "name",         headerName: "Name"         , flex: 3},
        {field: "description",  headerName: "Description"  , flex: 5},
        {field: "id",           headerName: ""             , flex: 1,
            renderCell: (params) => (
                <Button variant="contained" color="secondary" size="small" to={'/investigations/'+params.value} component={NavLink}>
                    {t('See')}
                </Button>
            )
        }
    ];

    //const investigationsData = formatDataAsCols(investigations, columns);
    const investigationsData = [];
    investigations.map((investigation, index) => {
        let data = {};
        columns.map(column => {
            data[column.field] = investigation[column.field];
        });
        investigationsData.push(data);
    });

    return <Card elevation={3}>
        <CardHeader
            title={t("InvestigationsTitle")}
        />
        <CardContent>
            <div style={{height:400}}>
                <DataGrid columns={columns} rows={investigationsData} pageSize={6}  />
            </div>
        </CardContent>
        <CardActions>
            <Button variant="contained" size="medium" color="secondary" onClick={handleOpenCreate}>
                {t('CreateNewInvestigation')}
            </Button>
            <InvestigationCreate open={openCreate} onClose={handleCloseCreate}/>
        </CardActions>
    </Card>
}

function InvestigationCreate({open, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const [name, setName] = useState(null);
    const handleName = (event) => setName(event.target.value);

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const handleCreate = () => {
        if (name && description) {
            dispatch(createInvestigation({
                body: {
                    name: name,
                    description: description,
                }
            }));
            onClose();
        }
    }

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
        <DialogTitle
            title={t('CreateInvestigation')}
        />
        <DialogContent>
            <FormGroup>
                <TextField variant="standard" value={name} onChange={handleName} placeholder="Name" />
            </FormGroup>
            <FormGroup>
                <TextField variant="standard" value={description} onChange={handleDescription} placeholder="Description" />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" size="medium" color="secondary" onClick={handleCreate}>
                {t('CreateNewInvestigation')}
            </Button>
        </DialogActions>
    </Dialog>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));