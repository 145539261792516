import React, {useState, useEffect} from "react";
import {
    makeStyles,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, FormControlLabel, FormLabel, Button, RadioGroup, Radio,
    Stepper, TextField, Step, StepLabel
} from "@material-ui/core";
import client from "../../api/client";

export default function CollectionImport({open, onClose, collectionId}) {
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    // STEP 0
    const [sourceType, setSourceType] = useState();
    const handleSourceType = (event, value) => setSourceType(value);
    const handleStep1 = () => {
        if (sourceType) setActiveStep(1);
    }

    // STEP 1 16Z8uaYyjtG8MZO8j4wKA5YCp7jserY9Q_Q9MVRNVZVI B1:C23
    const [googleSheetName, setGoogleSheetName] = useState();
    const handleGoogleSheetName = (event) => setGoogleSheetName(event.target.value);
    const [googleSheetData, setGoogleSheetData] = useState();
    const handleGoogleSheetData = (event) => setGoogleSheetData(event.target.value);

    const handleStep2 = () => {
        if (0) setActiveStep(2);
    }

    return <Dialog open={open} maxWidth="md" fullWidth onClose={onClose}>
        <DialogTitle>Import</DialogTitle>
        <DialogContent>
            <Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel>
                {steps.map((label, i)=>{
                    return <Step key={label} color="secondary">
                         <StepLabel color="secondary">{label.name}</StepLabel>
                     </Step>
                })}
            </Stepper>
        </DialogContent>

            {activeStep === 0 && <>
                <DialogContent>
                    <FormControl component="fieldset" className={classes.FieldSet}>
                        <FormLabel component="legend">Choose Import source</FormLabel>
                        <RadioGroup aria-label="sourceType" name="sourceType" value={sourceType} onChange={handleSourceType}>
                            <FormControlLabel value="GoogleSheet" control={<Radio />} label={"Google Sheet"} />
                            <FormControlLabel disabled value="CSVSheet File" control={<Radio />} label={"CSV File"} />
                            <FormControlLabel disabled value="CSVSheet URL" control={<Radio />} label={"CSV URL"} />
                            <FormControlLabel disabled value="WebPage" control={<Radio />} label={"WebPage"} />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={handleStep1}>Next</Button>
                </DialogActions>
            </>}

        {activeStep === 1 && <>
            <DialogContent>
                <FormControl component="fieldset" className={classes.FieldSet}>
                    {sourceType === "GoogleSheet" && <>
                        <TextField variant="outlined" value={googleSheetName} onChange={handleGoogleSheetName} label={"Name of sheet"} className={classes.TextField} />
                        <TextField variant="outlined" value={googleSheetData} onChange={handleGoogleSheetData} label={"Range of data"} className={classes.TextField} />
                    </>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => setActiveStep(0)}>Previous</Button>
                <Button variant="contained" color="secondary" onClick={handleStep2}>Next</Button>
            </DialogActions>
        </>}
    </Dialog>
}

function getSteps() {
    return [
        {
            name: 'Choose origin type',
        },
        {
            name: 'Input origin parameters',
        },
        {
            name: 'Check data sample',
        },
        {
            name: 'Perform import',
        }
    ]
}

const useStyles = makeStyles((theme) => ({
    FieldSet: {
        width: "50%"
    },
    TextField: {
        margin: theme.spacing(1),
        width: "100%"
    }
}));