import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/demo';
import {selectPathOfCategory} from "./treeCategoriesSlice";

const treeFieldsAdapter = createEntityAdapter();
const initialState = treeFieldsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all treeFields
export const fetchTreeFields = createAsyncThunk(
    'treeFields/fetchTreeFields',
    async (parameters, thunkAPI) => {
        const response = await client.get('/tree/field/all');
        return response.fields;
    }
);

// THE REDUCERS
const treeFieldsSlice = createSlice({
    name: 'treeFields',
    initialState,
    extraReducers: {
        [fetchTreeFields.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchTreeFields.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched treeFields to the array
            treeFieldsAdapter.upsertMany(state, action.payload);
        },
        [fetchTreeFields.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        }
    },
});

// export const {  } = treeFieldsSlice.actions;

export default treeFieldsSlice.reducer

export const {
    selectAll: selectAllTreeFields,
    selectById: selectTreeFieldById,
    selectIds: selectTreeFieldIds,
} = treeFieldsAdapter.getSelectors((state) => state.treeFields);

export const selectFieldsByCategory = createSelector(
    [selectAllTreeFields, (state, categoryId) => categoryId],
    (treeFields, categoryId) => treeFields.filter((treeField) => (
        treeField.tree_category_id === categoryId
    ))
);

export const selectInheritedFieldsByCategory = (state, categoryId) => {
    const fields = [];
    let currentCategory = categoryId;
    let currentFields;
    while (currentCategory) {
        const category = state.treeCategories.entities[currentCategory];
        fields.push(...selectFieldsByCategory(state, currentCategory));
        currentCategory = category ? category.parent_id : null;
    }
    // const fields =
    return fields;
};