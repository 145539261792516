import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import {useDispatch, useSelector} from "react-redux";
import {useTranslation}     from 'react-i18next';
import { makeStyles, fade, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, Link, NavLink, Redirect } from "react-router-dom";

import Hidden               from '@material-ui/core/Hidden';
import Toolbar              from '@material-ui/core/Toolbar';
import Button               from "@material-ui/core/Button";
import List                 from '@material-ui/core/List';
import Typography           from '@material-ui/core/Typography';
import ListItem             from '@material-ui/core/ListItem';
import ListItemAvatar       from "@material-ui/core/ListItemAvatar";
import ListItemIcon         from "@material-ui/core/ListItemIcon";
import ListItemText         from "@material-ui/core/ListItemText";
import Divider              from "@material-ui/core/Divider";
import Avatar               from "@material-ui/core/Avatar";
import InputBase            from "@material-ui/core/InputBase";
import Badge                from '@material-ui/core/Badge';
import Menu                 from "@material-ui/core/Menu";
import MenuItem             from "@material-ui/core/MenuItem";
import MenuList             from "@material-ui/core";
import Fade                 from "@material-ui/core/Fade";

import MailIcon             from '@material-ui/icons/Mail';
import IconButton           from '@material-ui/core/IconButton';
import NotificationsIcon    from '@material-ui/icons/Notifications';
import SearchIcon           from '@material-ui/icons/Search';
import HomeIcon             from '@material-ui/icons/Home';
import VisibilityIcon       from '@material-ui/icons/Visibility';
import BusinessCenterIcon   from '@material-ui/icons/BusinessCenter';
import CollectionsIcon      from '@material-ui/icons/Collections';
import TrackChangesIcon     from '@material-ui/icons/TrackChanges';
import MapIcon              from '@material-ui/icons/Map';
import BookmarksIcon        from '@material-ui/icons/Bookmarks';
import PolicyIcon           from '@material-ui/icons/Policy';
import GroupIcon            from '@material-ui/icons/Group';
import StorageIcon          from '@material-ui/icons/Storage';
import TodayIcon            from '@material-ui/icons/Today';
import EqualizerIcon            from '@material-ui/icons/Equalizer';

import LanguageIcon         from '@material-ui/icons/Language';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import SecurityIcon         from '@material-ui/icons/Security';
import GroupWorkIcon        from '@material-ui/icons/GroupWork';
import MuseumIcon           from '@material-ui/icons/Museum';

import AccountBalanceIcon   from '@material-ui/icons/AccountBalance';

import CriminalCasesRouter  from "./CriminalCase/CriminalCasesRouter.jsx";
import InvestigationsRouter from './Investigation/InvestigationsRouter.jsx'
import CollectionsRouter    from "./Collection/CollectionsRouter";
import Identification       from "./Identification/IdentificationCreate.jsx";
import QueriesRouter        from "./Query/QueriesRouter.jsx";
import TreeViewer           from "./Tree/TreeViewer";
import Home                 from './Home/Home.jsx';
import ContentPage          from "./ContentPage";
import Datasources          from "./Datasource/Datasources.jsx";
import SurveysRouter        from "./Survey/SurveysRouter";


import {getCurrentUser, logoutUser, isGranted}     from "../store/reducers/currentUserSlice";

import {isMobile}           from "react-device-detect";

import Selector from "./Selectors/Drawer/Selector";

import {Box} from "@material-ui/core";
import UsersRouter from "./User/UsersRouter";
import {Apartment} from "@material-ui/icons";
import OrganizationsRouter from "./Organization/OrganizationsRouter";
import {fetchCollections, selectAllCollections} from "../store/reducers/collectionsSlice";

// TOOLBAR
export function NavigationToolbar({menuOpener}) {
    const currentUser = useSelector(getCurrentUser);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    console.log(i18n);
    const classes = useStyles();
    const handleChooseLanguage = (language) => {
        i18n.changeLanguage(language);
    }
    const handleUserMenu = (command) => {
        if (command === "logout") {
            window.location = process.env.REACT_APP_LOGOUT
        }
    }

    const getTitle = () => {
        // console.log("CURRENTUSER", currentUser);

        if (currentUser && currentUser.ui_title) {
            return currentUser.ui_title
        }
        else if (isGranted(currentUser, false)) {
            return "Parcs ADMINISTRATION"
        }
        else if (isGranted(currentUser, 'prevision')) {
            return "Parcs-PREVISION"
        }
        else if (isGranted(currentUser, 'lea')) {
            return "Parcs-LEA"
        }
        return "ARTE-FACT"
    }

    return <Toolbar>
        {menuOpener}

        <Typography variant="h6" noWrap>
            {getTitle()}
        </Typography>
        {/*SMALL VIEW*/}
        <Hidden mdUp={true}>
            <Box className={classes.grow} />
            <Box>
                <ButtonMenu
                    badge={0}
                    items={[{text:'fr'}, {text:'en'}]}
                    onclick={handleChooseLanguage}
                    text={i18n.language}
                />
            </Box>
            <ButtonMenu
                badge={0}
                icon={
                    <Avatar className={classes.smallAvatar} alt={currentUser.username} title={currentUser.username}>
                        {currentUser.firstName ? currentUser.firstName.charAt(0).toUpperCase() : "!"}
                        {currentUser.lastName ? currentUser.lastName.charAt(0).toUpperCase() : "?"}
                    </Avatar>
                }
                items={[
                    {text: `${currentUser.firstName} ${currentUser.lastName}`},
                    {text: t('logout'), short:'logout'}
                ]}
                onclick={handleUserMenu}
            />
        </Hidden>
        {/*BIG VIEW*/}
        <Hidden smDown={true}>
            <Box className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Box>
            <Box className={classes.grow} />
            <Box>
                <ButtonMenu
                    badge={0}
                    icon={<LanguageIcon />}
                    items={[{text:'fr'}, {text:'en'}]}
                    onclick={handleChooseLanguage}
                    text={i18n.language}
                />
            </Box>
            <Box>
                {/*<Button variant={"text"} startIcon={<LanguageIcon scale={2} />} to="/connect" component={NavLink}>NetConnect</Button>*/}
            </Box>
            <ButtonMenu
                badge={0}
                icon={<TodayIcon />}
                items={[
                    {text: t('Ouvrir le calendrier Personnel')},
                    {text: t('Ouvrir le calendrier du Service')}
                ]}
            />
            <ButtonMenu
                badge={0}
                icon={<MailIcon />}
                items={[
                    {text: t('Pas de nouveaux messages')},
                ]}
            />
            <ButtonMenu
                badge={2}
                icon={<NotificationsIcon />}
                items={[
                    {text: t('2 Surveillances ont reçu des notifications')},
                ]}
            />
            <ButtonMenu
                badge={0}
                icon={
                    <Avatar className={classes.avatar} alt={currentUser.username} title={currentUser.username}>
                        {currentUser.firstName ? currentUser.firstName.charAt(0).toUpperCase() : "!"}
                        {currentUser.lastName ? currentUser.lastName.charAt(0).toUpperCase() : "?"}
                    </Avatar>
                }
                items={[
                    {text: `${currentUser.firstName} ${currentUser.lastName}`, short:''},
                    {text: currentUser.type, short:''},
                    {text: t('logout'), short:'logout'}
                ]}
                onclick={handleUserMenu}
            />
        </Hidden>

    </Toolbar>;
}

function ButtonMenu({icon, text, items, badge, onclick, small}) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (item) => setAnchorEl(null);
    const handleChoose = (item) => {
        if (onclick) {
            onclick(item);
        }
        setAnchorEl(null);
    }
    return <div>
        {
            text ?
            <div onClick={handleClick}>
                <IconButton aria-label="" color="inherit">
                    <Badge badgeContent={badge} color="secondary">
                        {icon}
                    </Badge>
                </IconButton>
                {text}
            </div>
            :
            <IconButton aria-label="" color="inherit" onClick={handleClick}>
                <Badge badgeContent={badge} color="secondary">
                    {icon}
                </Badge>
            </IconButton>
        }

        {items
            ? <Menu
                open={anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Fade}
            >
                {items.map((item, i)=>{
                    return <MenuItem key={i} onClick={() => handleChoose(item.short ? item.short : item.text)}>
                        {item.text}
                    </MenuItem>
                })}
            </Menu> : null
        }
    </div>;
}

// NAVIGATION
export function NavigationItems () {
    const {t, i18n} = useTranslation();
    //console.log("CALLING", currentUser)
    const classes = useStyles();

    const currentUser = useSelector(getCurrentUser);
    // TODO: replace with nice grant handlers
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);

    return <List>
        <LinkItem link="/home"              access={true}       label="HomePageLink"        icon={<HomeIcon />} />
        <LinkItem link="/cases"             access={lea}        label="CriminalCasesLink"   icon={<BusinessCenterIcon />} />
        <LinkItem link="/investigations"    access={lea}        label="InvestigationsLink"  icon={<PolicyIcon />} />
        <Divider />
        <LinkItem link="/identification"    access={true}       label="IdentificationLink"  icon={<VisibilityIcon />} />
        <LinkItem link="/queries"           access={true}       label="QueriesLink"         icon={<SearchIcon />} />
        <LinkItem link="/surveys"           access={true}       label="SurveyLink"          icon={<TrackChangesIcon />} />
        <LinkItem link="/collections"       access={lea}        label="CollectionsLink"     icon={<CollectionsIcon />} />
        {prevision && !admin && <PersonnalCollectionLink />}
        <Divider />
        <ExtLinkItem link="http://data.parcs-pro.com" access={true} label="Statistics"      icon={<EqualizerIcon />} />
        <LinkItem link="/datasources"       access={true}       label="Datasources"         icon={<StorageIcon />} />
        <LinkItem link="/places"            access={lea}        label="LocationLink"        icon={<MapIcon />} />
        <LinkItem link="/thesaurus"         access={true}       label="ThesaurusLink"       icon={<BookmarksIcon />} />
        <LinkItem link="/users"             access={admin}      label="UsersLink"           icon={<GroupIcon />} />
        <LinkItem link="/organizations"     access={admin}      label="OrganizationsLink"   icon={<Apartment />} />
    </List>;
}

export function PersonnalCollectionLink() {
    const dispatch = useDispatch();
    const status = useSelector(state => state.collections.status);
    const collections = useSelector(selectAllCollections);
    useEffect(()=>{
        if (status === 'idle')dispatch(fetchCollections(null));
    }, [status])

    const [content, setContent] = useState(null);
    useEffect(()=>{
        if (collections.length > 1 ) {
            setContent(<LinkItem link="/collections"     access={true}      label="Objects"   icon={<CollectionsIcon />} />)
        }
        else if (collections.length === 1) {
            setContent(<LinkItem link={`/collections/${collections[0].id}`}     access={true}      label="Objects"   icon={<CollectionsIcon />} />)
        }
    }, [collections])

    return content;
}

// MAIN
export function NavigationMain() {

    const currentUser = useSelector(getCurrentUser);
    // TODO: replace with nice grant handlers
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);

    const {t} = useTranslation();
    return <Switch>
        <Route path="/cases">
            <CriminalCasesRouter />
        </Route>
        <Route path="/investigations">
            <InvestigationsRouter />
        </Route>
        <Route path="/identification">
            <Identification />
        </Route>
        <Route path="/identification">
            <Identification />
        </Route>
        <Route path="/queries">
            <QueriesRouter />
        </Route>
        <Route path="/surveys">
            <SurveysRouter title={t("Surveys")} />
        </Route>
        <Route path="/collections">
            <CollectionsRouter />
        </Route>
        <Route path="/places">
            <ContentPage title={t("Places")} content="" />
        </Route>
        <Route path="/thesaurus">
            <TreeViewer />
        </Route>
        <Route path="/datasources">
            <Datasources />
        </Route>
        <Route path="/organizations">
            <OrganizationsRouter />
        </Route>
        <Route path="/users">
            <UsersRouter />
        </Route>
        <Route path="/home" exact>
            <Home />
        </Route>
        <Redirect to='/home' />
    </Switch>;
}

function LinkItem({icon, link, label, access}) {
    const classes = useStyles();
    const {t} = useTranslation();
    if (!access)return null;
    return <ListItem component={NavLink} to={link} className={classes.link} activeClassName={classes.activeLink} >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{t(label)}</ListItemText>
    </ListItem>;
}

function ExtLinkItem({icon, link, label, access}) {
    const classes = useStyles();
    const {t} = useTranslation();
    if (!access)return null;
    return <a href={link} target="_blank">
        <LinkItem className={classes.link} activeClassName={classes.activeLink} >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{t(label)}</ListItemText>
    </LinkItem></a>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
        //background: 'linear-gradient(0deg, #4E4E4E 0%, #B4B4B4 100%)',
        //backgroundAttachment: 'fixed'
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.contrastText,
        textDecoration: 'none'
    },
    activeLink: {
        backgroundColor: theme.palette.secondary.main
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    menuButton: {
        marginRight: 36,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    avatar: {
        // marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: 'black'
    },
    smallAvatar: {
        backgroundColor: theme.palette.secondary.main,
        color: 'black'
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));
