import { configureStore } from '@reduxjs/toolkit';

import collectionsReducer from './reducers/collectionsSlice';
import collectionTypesReducer from './reducers/collectionTypesSlice';
import collectionTypesFieldsReducer from './reducers/collectionTypesFieldsSlice';
import imagesReducer from './reducers/imagesSlice';
import objectReducer from './reducers/objectsSlice';
import userReducer from './reducers/usersSlice';
import organizationReducer from './reducers/organizationsSlice';
import currentUserReducer from './reducers/currentUserSlice';
import investigationsReducer from "./reducers/investigationsSlice";
import criminalCasesReducer from "./reducers/criminalCasesSlice";
import tasksReducer from "./reducers/tasksSlice";
import surveyReducer from "./reducers/surveysSlice";
import searchReducer from "./reducers/searchSlice";
import queriesReducer from "./reducers/queriesSlice";
import treeCategoriesReducer from "./reducers/treeCategoriesSlice";
import treeThesaurusReducer from "./reducers/treeThesaurusSlice";
import treeFieldsReducer from "./reducers/treeFieldsSlice";
import datasourcesReducer from "./reducers/datasourcesSlice";
import fieldTypesReducer from "./reducers/fieldTypesSlice";
// HERE ADD THE SLICE OF REDUCERS
import preferencesSlice from "./reducers/preferencesSlice";
import drawerSelectorReducer from "../components/Selectors/Drawer/SelectorSlice";


export default configureStore({
    reducer: {
        collections: collectionsReducer,
        collectionTypes: collectionTypesReducer,
        collectionTypeFields: collectionTypesFieldsReducer,
        images: imagesReducer,
        objects: objectReducer,
        users: userReducer,
        currentUser: currentUserReducer,
        tasks: tasksReducer,
        investigations: investigationsReducer,
        criminalCases: criminalCasesReducer,
        surveys: surveyReducer,
        search: searchReducer,
        queries: queriesReducer,
        treeCategories: treeCategoriesReducer,
        treeThesauruses: treeThesaurusReducer,
        treeFields: treeFieldsReducer,
        datasources: datasourcesReducer,
        fieldTypes: fieldTypesReducer,
        organizations: organizationReducer,
        preferences: preferencesSlice,
        drawerSelector: drawerSelectorReducer,
    },
});
