import {useDispatch, useSelector} from "react-redux";
import React, {createRef, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {selectRootCategories} from "../../store/reducers/treeCategoriesSlice";
import {launchSearch} from "../../store/reducers/searchSlice";
import client_ai from "../../api/client_ai";
import {useHistory} from "react-router-dom";
import {createImage} from "../../store/reducers/imagesSlice";
import {createObject} from "../../store/reducers/objectsSlice";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel,
    Grid,
    IconButton, Radio, RadioGroup, TextField,
    Typography
} from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import {ArrowDropDown, Close, SkipNext} from "@material-ui/icons";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";

export default function CollectionObjectCreate({collectionId, handleClose}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileInput = createRef();
    const {t, i18n} = useTranslation();
    const form = useRef(null)

    const rootCategories = useSelector(selectRootCategories);

    const status = useSelector(state => state.search.status);
    const search = useSelector(state => state.search.status ==="success" ? state.search.search.search : null);
    const [showSearch, setShowSearch] = useState(true);

    const ecommerceSites = [
        { title: 'Ebay', value:'ebay'},
        { title: 'Le bon coin', value:'le_bon_coin'},
        { title: 'Catawiki', value:'catawiki'}
    ]
    const [targetSites, setTargetSites] = useState(null);
    const handleTargetSites = (event,values) => setTargetSites(values);

    FileReader.prototype.setFile = function(file) {
        this.file = file;
        return this;
    };
    const [image, setImage] = useState(null);
    const handleGetImage = () => {
        if (fileInput.current.files.length === 1) {
            const file = fileInput.current.files[0];
            const reader = new FileReader().setFile(file);
            reader.onload = function(event) {
                var data = {
                    data: 'data:'+this.file.type+';base64,'+window.btoa(this.result),
                    name: this.file.name
                }
                setImage(data);
                data['indexes'] = ['redListInt']
                console.log(data)
                dispatch(launchSearch(data));
            };
            reader.readAsBinaryString(file);
        }
    };
    const handleOpenFile = () => {
        fileInput.current.click();
    }
    const removeBackground = () => {
        var data = new FormData(form.current)
        client_ai.post("/remove_background", image).then(
            (response)=>{
                setImage({
                    url: response.url,
                    name: image.name
                });
            }
        )
    }
    const getImage = () => {
        if ('url' in image) return image.url;
        if ('data' in image) return image.data;
    }

    const [formName, setFormName] = useState("");
    const [formType, setFormType] = useState("");
    const history = useHistory();

    const actions = ["CreateAndEditObject", "CreateObjectAndAddAnother", "UploadImageAndAddAnother"]
    const [nextAction, setNextAction] = useState(actions[0]);
    const handleCreateObject = async () => {
        if (image) {
            let parameters = {...image, collectionId: collectionId}
            const setImage = await dispatch(createImage(parameters));
            const imageId = setImage.payload.id
            const setObject = await dispatch(createObject({
                collectionId: collectionId,
                imageId: imageId,
                categoryId: rootCategories[0].id
            }));
            console.log(setObject);
            history.push("/collections/"+collectionId+"/object/"+setObject.payload.id);
        }
    }

    return <Dialog open={true} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle>Ajouter un objet</DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    {
                        image
                            ?   <>
                                <img src={getImage()} style={{ width: '100%', height: 'auto'}}   />
                                <Button variant="contained" color="secondary" startIcon={<AddAPhotoIcon />} type="file" component='label' style={{marginTop:15, width: "100%"}}>
                                    <input ref={fileInput} name="file" onChange={handleGetImage} type="file" style={{display:"none"}} />
                                    {t('ChangePicture')}
                                </Button>
                                <Button variant="contained" color="secondary" startIcon={<AddAPhotoIcon />} onClick={removeBackground} component='label' style={{marginTop:15, width: "100%"}}>
                                    {t('RemoveBackground')}
                                </Button>
                            </>
                            :   <>
                                <Box display="flex" alignItems={"stretch"}  style={{cursor: 'pointer', border: '2px dashed #ccc', height: 200, width: "100%", backgroundColor: "#e0e0e0"}} onClick={handleOpenFile}>
                                    <AddAPhotoIcon style={{ margin:' 0 auto', top: '62px', position: 'relative', fontSize: '65px', color: '#999'}} />
                                </Box>
                                <Button style={{ display: 'none'}} variant="contained" color="secondary" type="file" component='label' style={{marginTop:15, width: "100%"}} >
                                    <input ref={fileInput} name="file" onChange={handleGetImage} type="file" style={{display:"none"}} />
                                    {t('ChoosePicture')}
                                </Button>
                            </>
                    }
                </Grid>
                <Grid item xs={8}>
                    {!!search && search.redListInt.length > 0 && <Card>
                        <CardHeader
                            title={<Typography>{t("SimilarObjectFound")}</Typography>}
                            action={<IconButton onClick={()=>setShowSearch(state=>!state)} >
                                {showSearch ? <Close /> : <ArrowDropDown />}
                            </IconButton>}
                        />
                        {
                            showSearch && <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <img src={search.redListInt[0].img} style={{ width: '100%', height: 'auto'}}   />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className="data_cat" style={{width: "100%", padding: "10px"}}>
                                            <b>{t('ObjectName')} :</b> <Chip label={search.redListInt[0].data.keyTerm}/>
                                        </div>
                                        <div className="data_cat" style={{width: "100%", padding: "10px"}}>
                                            <b>{t('ObjectType')} :</b> <Chip label={search.redListInt[0].data.type}/></div>
                                        <div className="data_cat" style={{width: "100%", padding: "10px"}}>
                                            <b>{t('ObjectMaterial')} :</b>
                                            {search.redListInt[0].data.material.split(',').map((val, index) => {
                                                return <Chip label={val}/>
                                            })
                                            }
                                        </div>
                                        <div className="data_cat" style={{width: "100%", padding: "10px"}}>
                                            <b>{t('ObjectOrigin')} :</b> <Chip
                                            label={search.redListInt[0].data.estimatedOrigin}/></div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        }
                    </Card>}
                    <FormGroup className={classes.formGroup}>
                        <TextField
                            fullWidth
                            value={formName}
                            label={t("ObjectName")}
                            onChange={(event)=>setFormName(event.target.value)}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                        <FormControl component={"fieldset"} className={classes.fieldset}>
                            <FormLabel component={"legend"}>{t('Action')}</FormLabel>
                            <RadioGroup name={"nexAction"} value={nextAction} onChange={(event)=>setNextAction(event.target.value)}>
                                {actions.map(action=>(
                                    <FormControlLabel
                                        control={<Radio />}
                                        label={<Typography variant={"small"}>{t(action)}</Typography>}
                                        value={action} />))}
                            </RadioGroup>
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} disabled={!image} onClick={handleCreateObject} startIcon={<SkipNext />}>{t('Next')}</Button>
        </DialogActions>
    </Dialog>
}


// style of the layout
const useStyles = makeStyles((theme) => ({
    badge: {

    },
    scrollPaper: {
        alignItems: 'baseline'
    },
    image: {
        borderStyle: 'hash',
        borderColor: theme.palette.primary.contrastText,
        borderWidth: 1,
        padding: theme.spacing(1),
    },
    selected: {
        backgroundColor: "#cccccc"
    },
    objectCollection: {
        marginTop: theme.spacing(3),
        borderTop: "1px dotted #666"

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    },
    formGroup: {
        marginBottom: "1rem"
    }
}));
