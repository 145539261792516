import React, {useState, useEffect, createRef} from "react";
import Drawer from "@material-ui/core/Drawer";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector, useDispatch} from "react-redux";
import {
    selectDrawerVisible, selectShowCategories, selectShowFields, selectShowThesaurus,
    setShowCategories, setShowFields, setShowThesaurus
} from "./SelectorSlice";
import clsx from "clsx";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {fetchTreeCategories} from "../../../store/reducers/treeCategoriesSlice";
import {fetchTreeThesauruses} from "../../../store/reducers/treeThesaurusSlice";
import {fetchTreeFields} from "../../../store/reducers/treeFieldsSlice";
import {fetchFieldTypes} from "../../../store/reducers/fieldTypesSlice";
import {ToggleButtonGroup} from "@material-ui/lab";
import {Code, Label} from "@material-ui/icons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import SelectorCategories from "./SelectorCategory";
import SelectorFields from "./SelectorFields";
import SelectorFieldPanels from "./SelectorFieldsThesaurus";
import SelectorFieldThesaurusPanels from "./SelectorFieldsThesaurus";

const drawerWidth = 280;

export default function Selector() {
    const open = useSelector(selectDrawerVisible)
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    // BOOT
    const statusCategories = useSelector(state => state.treeCategories.status);
    const statusThesauruses = useSelector(state => state.treeThesauruses.status);
    const statusFields = useSelector(state => state.treeFields.status);
    const statusTypes = useSelector(state => state.fieldTypes.status);
    useEffect(()=>{
        if (statusCategories === 'idle') dispatch(fetchTreeCategories(null));
        if (statusThesauruses === 'idle') dispatch(fetchTreeThesauruses(null));
        if (statusFields === 'idle') dispatch(fetchTreeFields(null));
        if (statusTypes === 'idle') dispatch(fetchFieldTypes(null));
    }, [statusCategories, statusThesauruses, statusFields, dispatch]);

    // TOGGLE PANELS
    const showCategories = useSelector(selectShowCategories);
    const handleShowCategories = (event) => {dispatch(setShowCategories(!showCategories))}
    const showFields = useSelector(selectShowFields);
    const handleShowFields = (event) => {dispatch(setShowFields(!showFields))}
    const showThesaurus = useSelector(selectShowThesaurus);
    const handleShowThesaurus = (event) => {dispatch(setShowThesaurus(!showThesaurus))}

    return <Drawer
        variant="persistent"
        anchor="right"
        open={open}
        className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
        })}
        classes={{
            paper: clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            }),
        }}
    >
        <div className={classes.inside}>
            <div className={classes.toolBar}>
                <Typography>{t('Tree')}</Typography>
                <ToggleButtonGroup>
                    <ToggleButton value="multipleSelect" selected={showCategories} style={{width: 30, height:30}} size="small" onClick={handleShowCategories}>
                        <Label fontSize={"small"} />
                    </ToggleButton>
                    <ToggleButton value="multipleSelect" selected={showFields} style={{width: 30, height:30}} size="small" onClick={handleShowFields}>
                        <Code fontSize={"small"} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <SelectorCategories />
            <SelectorFields />
            <SelectorFieldThesaurusPanels />
        </div>
    </Drawer>
}

export const panelStyles = {

}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    drawer: {
        marginTop: 64,
        flexShrink: 0,
        zIndex: theme.zIndex.modal + 1
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(1) + 1,
        [theme.breakpoints.up('sm')]: {
            width:  1,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    inside: {
        padding: 5
    },
    toolBar: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        paddingBottom:5,
    }
}));