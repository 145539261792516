import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
    current
} from '@reduxjs/toolkit';

import {objectAdded} from "./objectsSlice";
import client from '../../api/client';
import client_ai from '../../api/client_ai';

// Create EntityAdapter
const surveysAdapter = createEntityAdapter();
const initialState = surveysAdapter.getInitialState({
    status: 'idle',
    error: null,
    search: null
});

// fetch Surveys
export const fetchSurveys = createAsyncThunk(
    'surveys/fetchSurveys',
    async () => {
        const response = await client.get('/survey/getAll');
        return response.surveys;
    });

// fetch Survey detail
export const fetchSurveyById = createAsyncThunk(
    'surveys/fetchSurveyById',
    async (surveyId, thunkAPI) => {
        const response = await client.get('/survey/get/'+surveyId, {});
        const images = await client_ai.get('/survey/images/'+surveyId, {});
        var survey = response.survey;
        survey.images = images.data
        return response.survey;
    });

// add Survey
export const addNewSurvey = createAsyncThunk(
    'surveys/addNewSurvey',
    async (initialSurveys) => {
        const response = await client.post('/survey/create', initialSurveys.body);
        return response.survey;
    });

// Creating the slice
const surveysSlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchSurveys.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchSurveys.fulfilled]: (state, action) => {
            state.status = 'success';
            surveysAdapter.upsertMany(state, action.payload);
        },
        [fetchSurveys.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchSurveyById.pending]: (state, action) => {},
        [fetchSurveyById.fulfilled]: (state, action) => {
            surveysAdapter.upsertOne(state, action.payload);
        },
        [fetchSurveyById.rejected]: (state, action) => {}
    },
});

export const { surveyAdded, surveyUpdated } = surveysSlice.actions;

export default surveysSlice.reducer

export const {
    selectAll: selectAllSurveys,
    selectById: selectSurveyById,
    selectIds: selectSurveyIds,
} = surveysAdapter.getSelectors((state) => state.surveys);

export const selectSurveysByUser = createSelector(
    [selectAllSurveys, (state, userId) => userId],
    (surveys, userId) => surveys.filter((survey) => survey.user === userId)
);

export const selectSurveysByInvestigation = createSelector(
    [selectAllSurveys, (state, investigationId) => investigationId],
    (surveys, investigationId) => surveys.filter((survey) => survey.investigation === investigationId)
);

export const selectSurveysByCriminalCase = createSelector(
    [selectAllSurveys, (state, criminalCaseId) => criminalCaseId],
    (surveys, criminalCaseId) => surveys.filter((survey) => survey.criminal_case === criminalCaseId)
);

export const selectSearch = (state) => {
    return state.search
};

/*
export const selectSurveyRootImages = (state, surveyId) => {
    const survey = state.surveys.entities[surveyId];
    return survey.images === undefined ? [] : survey.images.filter(image => image.group === null);
};

export const selectSurveyObjects = (state, surveyId) => {
    const survey = state.surveys.entities[surveyId];
    return survey.objects === undefined ? [] : survey.objects;
};
export const selectSurveyGroupImages = createSelector(
    [
        (state, surveyId) => selectSurveyById(surveyId),
        (state, imageId) => imageId
    ],
    (survey, imageId) => survey.images.filter(image => image.group === imageId)
);*/
