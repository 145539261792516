import React, {useState, useEffect, createRef, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import {useTranslation} from "react-i18next";
import { useHistory, useParams} from "react-router-dom";

import {
    addNewSurvey,
    fetchSurveyById,
    selectSurveyById
} from "../../store/reducers/surveysSlice";
import {Dialog, DialogContent, DialogTitle, LinearProgress, Tab, Tabs} from "@material-ui/core";

export default function Survey({}) {
    const { surveyId } = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory()

    const survey = useSelector(state => selectSurveyById(state, surveyId));

    useEffect(()=>{
        if (!survey) {
            dispatch(fetchSurveyById(surveyId));
        }
    }, [surveyId]);

    const [currentTab, setCurrentTab] = useState(0);

    return !survey ? <LinearProgress color={"secondary"} />
        : <Dialog open={true} onClose={()=>history.push(`/surveys`)} maxWidth={"md"} fullWidth>
        <DialogTitle>{survey.name}</DialogTitle>
        <DialogContent>
            {/* TODO: form here */}
            <Grid container spacing={2} justify={"center"}>
                <Grid md={4} sm={6} xs={12} item>
                    {
                        <img src={ survey ? survey.search.image.url : null} style={{ width: '100%', height: 'auto'}}   />
                    }
                </Grid>
                <Grid md={8} sm={6} xs={12} item>
                    <Tabs
                        value={currentTab}
                        // indicatorColor={"secondary"}
                        // textColor={"primary"}
                        onChange={(event, newValue)=>setCurrentTab(newValue)}
                        className={classes.tabs}
                    >
                        <Tab className={classes.tab} label={t('SurveySettings')} />
                        <Tab className={classes.tab} label={t('SearchTerms')} />
                        <Tab className={classes.tab} label={t('Surveillance last hits')} />
                    </Tabs>
                    {
                        currentTab === 0 && <div style={{padding:'10px', margin:'10px'}}>
                            <Grid container spacing={2}>
                                {
                                    !!survey && <>
                                        <Grid item xs={2}>
                                            {t('Name')}
                                        </Grid>
                                        <Grid item xs={10}>
                                            {survey.name}
                                        </Grid>
                                    </>
                                }
                                {
                                    !!survey &&
                                    <>
                                        <Grid item xs={2}>
                                            {t('Target')}
                                        </Grid>
                                        <Grid item xs={10}>
                                            {survey.target.map((value, index) => (
                                                <div key={index} style={{}}>{value.title}</div>
                                            ))}
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </div>
                    }
                    {
                        currentTab === 1 && <div>
                            {survey ? survey.search.terms.map((value, index) => {return <div key={index} style={{ margin: '10px'}}>{value}</div>})  : null}
                        </div>
                    }
                    {
                        currentTab === 2 && <div>
                            {survey && survey.images
                                ?   survey.images.map((val, index)=>{
                                        return <div>
                                            <div style={{padding:'10px', margin:'10px'}}>
                                                <Card elevation={3} style={{height:'220px'}}>
                                                    <CardHeader style={{textTransform: "capitalize", height: "20px", overflow: "hidden"}} title={<Typography>{val.data.title}</Typography>} />
                                                    <CardContent style={{textAlign:'center'}}>
                                                        <a href={val.data.url} target="_blank">
                                                            <img style={{maxHeight: '100px'}} src={val.img}/>
                                                        </a>
                                                        <div>Prix: {val.data.price}€</div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                    })
                                : null
                            }
                        </div>
                    }
                </Grid>
            </Grid>
        </DialogContent>
   </Dialog>;
};

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        marginTop: ".5rem",
        width: '100%'
    },

    tab: {
        borderRight: '1px solid #ccc',
        color: '#000',
        fontSize: "1rem",
        wordWrap: true,
        wrap: true,
    },
}));
