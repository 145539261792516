import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';
import {updateObject} from "./objectsSlice";

const organizationsAdapter = createEntityAdapter();
const initialState = organizationsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

export const fetchOrganizations = createAsyncThunk('organizations/fetchOrganizations', async () => {
    const response = await client.get('/organizations');
    console.log("FETCHED", response)
    return response.organizations;
});

export const addNewOrganization = createAsyncThunk(
    'organizations/addNewOrganization',
    async (initialOrganizations) => {
        const response = await client.post('/organization/create', { put: initialOrganizations });
        return response.organization;
    }
);

export const updateOrganization = createAsyncThunk(
    'organizations/updateOrganization',
    async (parameters) => {
        const response = await client.post('/organization/'+parameters.organizationId, parameters.body);
        return response.organization;
    });

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState,
    reducers: {
        organizationUpdated(state, action) {
            const { id, title, content } = action.payload;
            const existingOrganization = state.entities[id];
            if (existingOrganization) {
                existingOrganization.title = title;
                existingOrganization.content = content;
            }
        },
    },
    extraReducers: {
        // fetchOrganizations
        [fetchOrganizations.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchOrganizations.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched organizations to the array
            console.log("PAYLOAD", action.payload);
            organizationsAdapter.upsertMany(state, action.payload);
        },
        [fetchOrganizations.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [updateOrganization.fulfilled]: organizationsAdapter.upsertOne,
        [addNewOrganization.fulfilled]: organizationsAdapter.addOne,
    },
});

export const { organizationAdded, organizationUpdated } = organizationsSlice.actions;

export default organizationsSlice.reducer

export const {
    selectAll: selectAllOrganizations,
    selectById: selectOrganizationById,
    selectIds: selectOrganizationIds,
} = organizationsAdapter.getSelectors((state) => state.organizations);

