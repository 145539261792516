import React, {useState, useEffect} from "react";
import clsx from "clsx";
import {ClickAwayListener, Input, InputBase, TextField, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import NiceTooltip from "./CustomToolTip";
import tdb from "../../services/TranslateDB";
import {useTranslation} from "react-i18next";

const KEY_ENTER = 13;
const KEY_ESC = 27;

export default function EditableText({initValue, field, type, textClasses, inputClasses, onChange, variant}) {
    const [editing, setEditing] = useState(false);
    const {t} = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState(initValue);
    const handleEdit = () => setEditing(true);
    const handleUnEdit = () => {
        setEditing(false);
        onChange(value, field);
    }
    const handleChange = (event) => setValue(event.target.value);
    const handleKey = (event) => {
        if (event.keyCode === KEY_ENTER) {
            handleUnEdit();
        }
        else if (event.keyCode === KEY_ESC) {
            setValue(initValue);
            setEditing(false);
        }
    };
    // classes={{root: {...textClasses, ...classes.typo}}}
    return !editing
        ?   <NiceTooltip title={`${t('EditField')} ${tdb(field.title)}`} placement={"top-start"}>
                <Typography onClick={handleEdit} className={classes.typo} variant={variant}>{initValue ? initValue : '(vide)'}</Typography>
            </NiceTooltip>
        :   <ClickAwayListener onClickAway={handleUnEdit}>
                {/*<TextField
                    fullWidth
                    variant={"outlined"}
                    onKeyUp={handleKey}
                    onChange={handleChange}
                    value={value}
                    type={type && type.name === "Date" ? "date" : null}
                />*/}
                {<InputBase
                    onKeyUp={handleKey}
                    onChange={handleChange}
                    autoFocus
                    value={value}
                    type={type && type.name === "Date" ? "date" : null}
                    classes={{
                        root: classes.root,
                        input: {...inputClasses, ...classes.input}
                    }}
                />}
            </ClickAwayListener>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        padding: 2,
        height: '2rem',
        border: "1px solid #ccc"
    },
    input: {
        padding: 0,
        width: '100%',
    },
    typo: {
        padding: 4,
        // height: '2rem',
        '-webkit-transition': 'background-color .5s ease-out',
        '-moz-transition': 'background-color .5s ease-out',
        '-o-transition': 'background-color .5s ease-out',
        'transition': 'background-color .5s ease-out',
        '&:hover': {
            backgroundColor: '#fdcaca',

        }
    }
}));