import React from "react";
import {ThemeProvider, createMuiTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import { Switch, Route, NavLink, useRouteMatch, useParams} from "react-router-dom";
import {useTranslation}     from 'react-i18next';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

import Surveys from "./Surveys.jsx";
import SurveyCreate from "./SurveyCreate.jsx";
import Survey from "./Survey.jsx";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../store/reducers/currentUserSlice";

const SurveysRouter = () => {
    const currentUser = useSelector(getCurrentUser);
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    // TODO improve breadbrumb
    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Switch>
                <Route path="/surveys/example">
                    <SurveysBreadCrumbs current={<Typography>Current</Typography>} />
                    <Survey />
                </Route>
                <Route path="/surveys/new">
                    <SurveysBreadCrumbs current={<Typography>New</Typography>} />
                    <SurveyCreate />
                </Route>
                <Route path="/surveys" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                    </Breadcrumbs>
                    <Surveys/>
                </Route>
                {/*<Route path="/surveys/:surveyId" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                    </Breadcrumbs>
                    <Surveys/>
                </Route>*/}
            </Switch>
        </ThemeProvider>
    </Container>;
};


function SurveysBreadCrumbs({current}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    return <Breadcrumbs separator="/">
        <Link to="/" component={NavLink} className={classes.Link}>
            {t('HomePageLink')}
        </Link>
        <Link to="/surveys/" component={NavLink} className={classes.Link}>
            {t('SurveyLink')}
        </Link>
        {current && current}
    </Breadcrumbs>
}

// Theme
const lightTheme = (prevision = false) => createMuiTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'black'
    }
}));

export default SurveysRouter;
