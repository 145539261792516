import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Container,
    Typography,
    Icon,
    createMuiTheme,
    ThemeProvider,
    TextField,
    LinearProgress,
    IconButton
} from "@material-ui/core";

import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";

import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";

import {DataGrid} from "@material-ui/data-grid";
import {selectAllQueries, fetchQueries} from "../../store/reducers/queriesSlice";
import {fetchDatasources, selectAllDatasources, selectAgreedDatasources} from "../../store/reducers/datasourcesSlice";
import Link from "@material-ui/core/Link";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import ViewComfy from "@material-ui/icons/ViewComfy";

import Grid from "@material-ui/core/Grid";
import tdb from "../../services/TranslateDB";
import {getCurrentUser} from "../../store/reducers/currentUserSlice";
import {OpenInNew} from "@material-ui/icons";

const Datasources = () => {
    const currentUser = useSelector(getCurrentUser);
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.datasources.status);
    const datasources = useSelector(selectAgreedDatasources);

    const [displayMode, setDisplayMode] = useState('table');
    const handleDisplayMode = (mode) => setDisplayMode(mode);

    const [filter, setFilter] = useState();
    const handleSetFilter = (event) => setFilter(event.target.value);


    // alert(status);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchDatasources(null));
        }
    }, [status, dispatch]);



    const getViewCell = (params) => {
        return <Button variant="contained" color="secondary" size="small">{t('Search')}</Button>
    };

    const getUrlCell = (params) => {
        return params.value
            ? <IconButton variant="contained" size="small" href={params.value} target={"_blank"} component={Link}><OpenInNew  color="secondary" /></IconButton>
            : null ;
    };

    const columns = [
        {field:"database",  headerName:"Nom",       flex:2},
        {field:"type",      headerName:"Type",      flex:1},
        {field:"category",  headerName:"Cat",       flex:1},
        {field:"access",    headerName:"Access",    flex:1},
        {field:"owner",     headerName:"Owner",       flex:1},
        {field:"typology",  headerName:"Typo",      flex:1},
        {field:"url",       headerName: "URL",      width:80, renderCell: getUrlCell, disableColumnMenu: true},

    ];

    const translated = datasources.map(source=>{
        return {
            ...source,
            category: tdb(source.category),
            access: tdb(source.access),
            typology: tdb(source.typology),
        }
    })

    const filtered = filter
        ? translated.filter(row=>(
            row.database.toLowerCase().includes(filter.toLowerCase())
            || row.type.toLowerCase().includes(filter.toLowerCase())
            || row.category.toLowerCase().includes(filter.toLowerCase())
            || row.owner.toLowerCase().includes(filter.toLowerCase())
            || row.typology.toLowerCase().includes(filter.toLowerCase())
        ))
        : translated
    ;



    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Card elevation={3}>
                <CardHeader
                    title={t('DataBases')}
                    action={
                        <Grid>
                            <TextField onChange={handleSetFilter} value={filter} variant={"standard"} label={t("Search")} className={classes.searchField} />
                            <ToggleButtonGroup size="medium" exclusive value={displayMode} onChange={handleDisplayMode}>
                                <ToggleButton value="table" className={classes.toggleButtons}>
                                    <ViewCompactIcon fontSize="default"/>
                                </ToggleButton>
                                <ToggleButton value="grid" className={classes.toggleButtons}>
                                    <ViewComfy fontSize="default"/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    }
                />
                <CardContent>
                    <div style={{height:window.innerHeight - 300}}>
                        {
                            status === "loading"
                                ? <LinearProgress variant={"indeterminate"} color={"secondary"} />
                                : <DataGrid columns={columns} rows={filtered} pageSize={100} density={"compact"} />
                        }
                    </div>
                </CardContent>
                <CardActions>
                    <Button title={t('CreateNewQuery')} variant="contained" color="secondary" to={"queries/new"} component={NavLink}>
                        {t('AddDataBase')}
                    </Button>
                </CardActions>
            </Card>
        </ThemeProvider>
    </Container>
};

export default Datasources;

// Theme
const lightTheme = (prevision = false) => createMuiTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
})



// style of the layout
const useStyles = makeStyles((theme) => ({

    searchField: {
        marginRight: theme.spacing(2)
    },
    toggleButtons: {
        height: 50,
    }
}));