import React from "react";
import { Switch, Route, NavLink, useRouteMatch, useParams} from "react-router-dom";
import {ThemeProvider, createMuiTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import CriminalCase from "./CriminalCase";
import CriminalCases from "./CriminalCases";
import CriminalCaseCreate from "./CriminalCaseCreate";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import StepButton from "@material-ui/core/StepButton";
import {useTranslation} from "react-i18next";
import Collection from "../Collection/Collection";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../store/reducers/currentUserSlice";

const CriminalCasesRouter = () => {
    const currentUser = useSelector(getCurrentUser);
    const classes = useStyles();
    const {t} = useTranslation();
    // TODO improve breadbrumb
    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Switch>
                <Route exact path="/cases" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                    </Breadcrumbs>
                    <CriminalCases/>
                </Route>
                <Route path="/cases/new">
                    <CriminalCasesBreadCrumbs current={<Typography>Create</Typography>} />
                    <CriminalCaseCreate/>
                </Route>
                <Route path="/cases/import">
                    <CriminalCasesBreadCrumbs current={<Typography>Import</Typography>} />
                    <CriminalCasesImport />
                </Route>
                <Route path="/cases/:criminalCaseId">
                    <CriminalCasesBreadCrumbs current={<Typography>Current</Typography>} />
                    <Current/>
                </Route>
            </Switch>
        </ThemeProvider>
    </Container>;
};

function Current() {
    const { criminalCaseId } = useParams();
    return <CriminalCase criminalCaseId={criminalCaseId} />
}

function CriminalCasesBreadCrumbs({current}) {
    const classes = useStyles();
    const {t} = useTranslation();

    return <Breadcrumbs separator="/">
        <Link to="/" component={NavLink} className={classes.Link}>
            {t('HomePageLink')}
        </Link>
        <Link to="/cases/" component={NavLink} className={classes.Link}>
            {t('CriminalCasesLink')}
        </Link>
        {current && current}
    </Breadcrumbs>
}

function getSteps() {
    return ['Create Request to API', 'Validate new entries', 'Import new cases'];
}

function CriminalCasesImport() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    return  <Card>
        <CardHeader title="Import" />
        <CardContent>
            <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
            </Stepper>
        </CardContent>
        <CardActions>
            <Button
                variant="contained"
                to="/cases"
                component={NavLink}
                color="primary"
            >
                cancel
            </Button>
            <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                color="secondary"
            >
                Back
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
            >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
        </CardActions>
    </Card>
}

// Theme
const lightTheme = (prevision = false) => createMuiTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default CriminalCasesRouter;