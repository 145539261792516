import React, {useState, useEffect} from "react";
import clsx from "clsx";
import {
    ClickAwayListener,
    Input,
    TextField,
    Checkbox,
    Typography,
    FormGroup,
    FormControlLabel,
    Radio, RadioGroup
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

export default function EditableBoolean({initValue, field, type, textClasses, inputClasses, onChange, variant}) {
    const [editing, setEditing] = useState(false);
    const {t} = useTranslation();
    const classes = useStyles();
    const v2l = {0:"0",1:"1",null:"null"}
    const l2v = {"0":0,"1":1,"null":null}
    const [value, setValue] = useState(v2l[initValue]);
    const handleEdit = () => setEditing(true);
    const handleUnEdit = () => {
        setEditing(false);
        onChange(l2v[value], field);
    }
    const handleChange = (event) => setValue(event.target.value);
    let label;
    switch (initValue) {
        case "0": case false: label = t('No');break;
        case "1": case true: label = t('Yes');break;
        default: label = t('unknown');
    }
    return !editing
        ? <Typography onClick={handleEdit} classes={textClasses} variant={variant}>{label}</Typography>
        : <ClickAwayListener onClickAway={handleUnEdit}>
            <RadioGroup
                aria-label="type"
                onClick={handleChange}
                value={value}
                row
            >
                <FormControlLabel value={"1"} control={<Radio />} label={t('Yes')} />
                <FormControlLabel value={"0"} control={<Radio />} label={t('No')} />
                <FormControlLabel value={"null"} control={<Radio />} label={t('null')} />
            </RadioGroup>
        </ClickAwayListener>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    base: {
        borderWidth: 0,
        padding:0
    },
    input: {
        padding: 0
    }
}));