import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles, useTheme, fade, createMuiTheme, ThemeProvider, withStyles} from "@material-ui/core/styles";

import { BrowserRouter as Router, Switch, Route, NavLink, useParams, useRouteMatch} from "react-router-dom";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CardHeader from "@material-ui/core/CardHeader";

import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {DataGrid} from "@material-ui/data-grid";

import {
    selectAllCollections,
    fetchCollections,
    selectCollectionById,
    createCollection
} from "../../store/reducers/collectionsSlice";
import {fetchCollectionTypes, selectAllCollectionTypes, selectCollectionTypeById, selectCollectionTypeIds} from "../../store/reducers/collectionTypesSlice";
import {fetchFieldTypes, selectAllFieldTypes} from "../../store/reducers/fieldTypesSlice";
import {getCurrentUser, isGranted} from "../../store/reducers/currentUserSlice";
import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup, Input,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import tdb from "../../services/TranslateDB";
import moment from "moment";
import {RemoveRedEye} from "@material-ui/icons";

// Manager Page
const CollectionList = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const status = useSelector(state => state.collections.status);
    const statusType = useSelector(state => state.collectionTypes.status);
    const statusFieldType = useSelector(state => state.fieldTypes.status);
    const collections = useSelector(selectAllCollections);
    const collectionTypeEntities = useSelector(state => state.collectionTypes.entities);

    const currentUser = useSelector(getCurrentUser);
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);

    useEffect(()=>{
        if (status === 'idle')dispatch(fetchCollections(null));
        if (statusType === 'idle')dispatch(fetchCollectionTypes(null));
        if (statusFieldType === 'idle')dispatch(fetchFieldTypes(null));
    }, [status, statusType, dispatch]);

    const [search, setSearch] = useState(false);
    const handleSearch = (event) => setSearch(event.target.value)

    let filteredCollections = search
        ? collections.filter(collection => {return collection.name.toLowerCase().includes(search.toLowerCase())})
        : collections
    filteredCollections = filteredCollections.map(c=>({...c, created: moment(c.created).fromNow(), dateSort: Date.parse(c.created)}))

    const [openCreate, setOpenCreate] = useState(false);
    const handleOpenCreate = () => setOpenCreate(true);
    const handleCloseCreate = () => setOpenCreate(false);

    if (status === 'loading' || statusType === "loading") {
        return <div>Loading</div>;
    }

    const getTypeCell = (params) => {
        return tdb(collectionTypeEntities[params.value].title);
    };

    const getViewCell = (params) => {
        return <Button startIcon={<RemoveRedEye fontSize={"small"} />} variant="contained" color="secondary" size="small" to={'/collections/'+params.value} component={NavLink}>
            {t('See')}
        </Button>
    };

    console.log(collections);

    const columns = [
        {field: "name", headerName: "Nom", flex: 3},
        {
            field: "created", headerName: "created",  flex: 2,
            sortComparator: (v1, v2, param1, param2) => param1.api.getCellValue(param1.id, 'dateSort') - param2.api.getCellValue(param2.id, 'dateSort')
        },
        {field: "collection_type_name", headerName: "Type",     flex: 2},
        {field: "id",                   headerName: "",         width: 80, renderCell: getViewCell, disableColumnMenu: true}
    ];

    return <Card>
        <CardHeader title={t("CollectionsTitle")} />
        <CardContent>
            <TextField onChange={handleSearch} variant={"outlined"} size={"small"} label={"search"} /> {search}
        </CardContent>
        <CardContent>
            <div style={{height:window.innerHeight - 360}}>
                <DataGrid density={"compact"} columns={columns} rows={filteredCollections} pageSize={20} />
            </div>
        </CardContent>
        <CardActions>
                <Button title={t('CreateNewCollection')} variant={"contained"} color={"secondary"} onClick={handleOpenCreate} style={{margin:'10px'}}>
                    {t('CreateNewCollection')}
                </Button>
                {admin && <Button title={t('ManageCollectionTypes')} variant={"contained"} color={"default"} to={'/collections/types'} component={NavLink} style={{margin:'10px'}}>
                    {t('ManageCollectionTypes')}
                </Button>}
            <CollectionCreate open={openCreate} onClose={handleCloseCreate} />
        </CardActions>

    </Card>;
};

export const CollectionCreate = ({open, onClose, investigationId}) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);

    const [type, setType] = useState(false);

    const [name, setName] = useState(null);
    const handleName = (event) => setName(event.target.value);

    // const [title, setTitle] = useState(null);
    // const handleTitle = (event) => setTitle(event.target.value);

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const collectionTypes = useSelector(selectAllCollectionTypes)
    const statusType = useSelector(state => state.collectionTypes.status);

    useEffect(()=>{
        if (statusType === 'idle') {
            dispatch(fetchCollectionTypes(null));
            dispatch(fetchFieldTypes(null));
        }
    }, [statusType, dispatch]);

    const handleChooseType = (event) => setType(event.target.value);
    const handleCreate = () => {
        if (type && name) {
            const body = {
                collection_type: parseInt(type),
                name: name,
                description: {
                    'default': description,
                    [i18n.language]: description
                }
            };
            if (investigationId)body.investigation_id = investigationId;
            dispatch(createCollection({body: body}));
            onClose();
        }
    }

    return <Dialog open={open} fullWidth maxWidth={"sm"} onClose={onClose}>
        <DialogTitle
            title={t('CreateCollection')}
        >
            {t('CreateCollection')}
        </DialogTitle>
        <DialogContent>
            <FormGroup>
                <TextField variant="standard" value={name} onChange={handleName} placeholder="Name" />
            </FormGroup>
            <FormGroup>
                <TextField variant="standard" value={description} onChange={handleDescription} placeholder="Description" />
            </FormGroup>
            <RadioGroup aria-label="type" value={type} onChange={handleChooseType}>
                <Grid container spacing={2}>
                    {collectionTypes.map((collectionType, index) => {
                        if (currentUser.type === "admin" || (collectionType.parameters && collectionType.parameters.usage && currentUser.type in collectionType.parameters.usage)) {
                            return <Grid item xs={5}>
                                <FormControlLabel value={`${collectionType.id}`} control={<Radio />} label={tdb(collectionType.title)} />
                            </Grid>
                        }
                    })}
                </Grid>
            </RadioGroup>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="default">
                {t('Cancel')}
            </Button>
            <Button onClick={handleCreate} color="secondary" variant="contained">
                {t('Create')}
            </Button>
        </DialogActions>
    </Dialog>;
};

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
}));

export default CollectionList;
