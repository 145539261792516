import React from "react";
import {ThemeProvider, createMuiTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import { Switch, Route, NavLink, useRouteMatch, useParams} from "react-router-dom";
import {useTranslation}     from 'react-i18next';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import Query from "./Query.jsx";
import Users from "./Users.jsx";

const UsersRouter = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    // TODO improve breadbrumb
    return <Container disableGutters={true}>
        <ThemeProvider theme={lightTheme}>
            <Switch>
                <Route exact path="/users" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                    </Breadcrumbs>
                    <Users/>
                </Route>
                <Route path="/users/:id">

                </Route>
            </Switch>
        </ThemeProvider>
    </Container>;
};

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default UsersRouter;