import React, {useRef} from "react";
import { useDrag, useDrop } from 'react-dnd';
import {useDispatch, useSelector} from "react-redux";
import {createObject} from "../../../store/reducers/objectsSlice";
import {selectRootCategories} from "../../../store/reducers/treeCategoriesSlice";
import * as colors from "./Borders";

export default  ({collectionId, children, categoryId, className}) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const rootCategories = useSelector(selectRootCategories);
    const [{canDrop, isOver}, connectDrop] = useDrop({
        accept: 'image',
        drop: (item, monitor) => {
            if  (!monitor.didDrop()) {
                dispatch(createObject({
                    collectionId: collectionId,
                    imageId: item.name,
                    categoryId: categoryId ? categoryId : rootCategories[0].id
                }));
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({shallow: true}),
            canDrop: monitor.canDrop(),
        }),
    });
    connectDrop(ref);
    let isActive = isOver && canDrop;
    let border= '2px solid transparent';
    if (isActive) {
        border= colors.activeColor;
    }
    else if (canDrop) {
        border= colors.canDropColor;
    }
    return (
        <div
            ref={ref}
            key={collectionId}
            style={{...style, border}}
            className={className}
        >
            {children}
        </div>
    );
}

const style = {

};